import {html} from "lit-element";
import {Helpers} from "../js/util/Helpers";

const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();

export const TemplateSubviewLogoHeader = el => html`
    <div class="sub-view-header">
        <a href="/${LANGUAGE}/"><img class="logo" src="${`${CDN_IMAGES_BRAND_ASSETS_URL}/logos/logo-full.svg`}"></a>
        <img class="icon" src="${`${CDN_ASSETS_URL}/icons/livechat.svg`}" @click=${el._onChatButtonClick}>
        <img class="icon close" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${el._onCloseButtonClick}>
    </div>
`;

export const TemplateSubviewBanner = el => {
    if (el.bannerData){
        return html`
            <div class="sv-banner">
                <a href="">
                    <img src="${el.bannerData.banner_background_img}">
                    <h5>${el.bannerData.banner_text}</h5>
                </a>
            </div>
        `;
    }else{
        return '';
    }
}
