import {html} from "lit-element";
import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {Validation} from "../../util/Validation";
import {APP_STRINGS} from "../../core/AppStrings";
import {FORM_MESSAGE_TYPE} from "../form-elements/FormElementBase";
import {CAPP_API} from "../../core/CAPPApi";
import {CAPPPopup} from "./CAPPPopup";

export class RestrictedCountryPopup extends PopupBase{
    constructor(csrfToken) {
        super(POPUP_MOBILE_ALIGN.TOP,POPUP_DESKTOP_ALIGN.CENTER);
        this._token=csrfToken;
        this._loading=false;
    }

    _renderContent() {
        return html`
            <div class="popup-wrap">
                <img class="icon" alt="Info Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}">
                <h4>${APP_STRINGS.getWPMLString('restrictedCountryPopup-title')}</h4>
                <div class="popup-body">
                    ${APP_STRINGS.getWPMLString('restrictedCountryPopup-message')}
                </div>
                <form class="reg-form" @submit=${this._onEmailSubmit}>
                    <capp-input ?disabled=${this._loading} label="${APP_STRINGS.getWPMLString('restrictedCountryPopup-mailInputLabel')}" name="emailAddress" type="text" @change=${this._handleInputChange}></capp-input>
                    <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('restrictedCountryPopup-mailSubmitButton')}</button>
                </form>
                
            </div>
        `;
    }

    _onEmailSubmit(e){
        e.preventDefault();
        let emailElement=this.renderRoot.querySelector('capp-input[name="emailAddress"]');
        this._validateInputElement(emailElement);
        if(emailElement.valid){
            console.log('send email address');
            this._loading=true;
            this.requestUpdate();

            CAPP_API.submitCountryRestrictionEmail(emailElement.value,this._token).then(data=>{
                let popupTitle, popupText;
                if(data.error){
                    popupTitle=APP_STRINGS.getWPMLString('Common-Error');
                    popupText=data.error.text;
                }else{
                    popupTitle=APP_STRINGS.getWPMLString('restrictedCountryPopup-emailSubmitSuccess-title');
                    popupText=APP_STRINGS.getWPMLString('restrictedCountryPopup-emailSubmitSuccess-message');
                }
                this._close();
                let p=new CAPPPopup(popupTitle,popupText,[]);
                p.desktopAlignment=POPUP_DESKTOP_ALIGN.CENTER;
                p.mobileAlignment=POPUP_MOBILE_ALIGN.TOP;
                p.classList.add('restricted-country-response');
            })
        }

    }

    _handleInputChange(e){
        this._validateInputElement(e.target);
    }

    _validateInputElement(inputEl) {
        let valid = true;
        let r;
        switch (inputEl.name){
            case 'emailAddress':
                r=Validation.validateEmail(inputEl.value);
                valid=r.valid;
                if(r.valid){
                    inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-email');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
        }
        this.requestUpdate();
        return valid;
    }

}

customElements.define('restricted-country-popup',RestrictedCountryPopup);