import {LitElement,html} from "lit-element";

export const FORM_MESSAGE_TYPE={
    WARNING:'warning',
    SUCCESS:'success',
    ERROR:'error'
}

export class FormElementBase extends LitElement{
    constructor() {
        super();
    }

    static get properties() {
        return {
            label: {type:String},
            message:{type:String},
            messageType:{type:String},
            name:{type:String}
        };
    }

    createRenderRoot() {
        return this;
    }


    render(){
        return html`
            ${this._renderLabel()}
            ${this._renderInput()}
            ${this._renderMessage()}
        `;
    }

    _renderLabel(){
        if(this.label){
            return html`<label class="form-label">${this.label}</label>`;
        }else{
            return html``;
        }

    }

    _renderInput(){
        return '';
    }

    _renderMessage(){
        if(!this.message){return '';}

        let msgClass,icon;
        switch (this.messageType){
            case FORM_MESSAGE_TYPE.ERROR:
                icon=`${CDN_ASSETS_URL}/icons/warning.svg`;
                msgClass=this.messageType;
                break;
            case FORM_MESSAGE_TYPE.WARNING:
                icon=`${CDN_ASSETS_URL}/icons/info.svg`;
                msgClass=this.messageType;
                break;
            case FORM_MESSAGE_TYPE.SUCCESS:
                icon=`${CDN_ASSETS_URL}/icons/single-check.svg`;
                msgClass=this.messageType;
                break;
            default:
                msgClass=FORM_MESSAGE_TYPE.WARNING;
                icon=`${CDN_ASSETS_URL}/icons/info.svg`;
        }

        return html`
            <div class="message ${msgClass}"><img class="icon" alt="Message Icon" src="${icon}"><span>${this.message}</span></div>
        `;
    }

}

