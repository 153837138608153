export const GAHelpers = {
    gaEvent(category,action){
        console.log('track GA event',category,action);
        try{
            // gtag('send', 'event', category, action);
            gtag('event', category, {
                action: action,
            });
        }
        catch(err){
            console.log('ERROR sending GA event',err);
        }
    }
}