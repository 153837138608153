import {html} from "lit-element";
import {FormElementBase} from "./FormElementBase";

export class CAPPMultiselect extends FormElementBase{
    constructor() {
        super();
        this._listData=[];
    }

    get value(){
        let selected=[];
        this._listData.forEach(item=>{
            if(item.selected){
                selected.push(item.value);
            }
        })
        return selected;
    }

    connectedCallback() {
        let options=this.renderRoot.querySelectorAll('option');
        console.log('options',options);
        options.forEach(item=>{
            let opt={
                label:item.innerText,
                value:item.value,
                selected:item.hasAttribute('selected')
            }
            this._listData.push(opt);
        })

        console.log('list items',this._listData);
        super.connectedCallback();
    }

    _renderInput() {
        return html`
            <div class="option-items noselect">
                ${this._renderOptionItems()}
            </div>
        `;
    }

    _renderOptionItems(){
        return html`
            ${this._listData.map(item=>html`
                <div .item=${item} class="option-btn ${item.selected?'selected':''}" @click=${this._handleItemClick}>
                    <span>${item.label}</span>
                    ${item.selected?html`<img alt="Check Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}">`:''}
                </div>
            `)}
        `;

    }

    _handleItemClick(e){
        e.preventDefault();
        let item=e.currentTarget.item;
        item.selected=!item.selected;
        this.requestUpdate();
        this._dispatchChangeEvent();
    }

    _dispatchChangeEvent(){
        let options = {
            detail: this.value
        };
        const event = new CustomEvent('change', options);
        this.dispatchEvent(event);
    }
}

customElements.define('capp-multiselect',CAPPMultiselect);