import {USER} from "../../core/User";
import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {CAPPInput} from "../form-elements/CAPPInput";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateSubviewLogoHeader} from "../../../templates/subViewElements";
import {APP_STRINGS} from "../../core/AppStrings";
import {ShowChatWindow} from "../../controllers/LivechatController";
import {ResetPasswordSubView} from "./ResetPasswordSubView";


export class LoginSubview extends SubViewBase{
    constructor() {
        super();
        this._loginError=null;
        this.redirectUrl=null;
        this._setupEventListeners();
    }

    _setupEventListeners(){

        USER.addListener("onLoginStateChange",e=>{
            console.log('login event',e);
            this._setLoading(false);
            if(e.loggedIn){this.close();}
        });
        USER.addListener("onLoginError",e=>{
            this._loginError=e.error;
            this._setLoading(false);
            if(e.errorID==8880080){
                this._showResetPasswordPopup();
            }
        });
    }

    _showResetPasswordPopup(){
        let resetPasswordSub=new ResetPasswordSubView(ResetPasswordSubView.MODE.LOCKED);
        resetPasswordSub.open();
    }


    renderViewContent() {
        return html`
            <div class="login-sub ${this._loading?'loading':''}">
                ${TemplateSubviewLogoHeader(this)}
                <div class="sub-view-content">
                    <h3>${APP_STRINGS.getWPMLString('LoginSubView-title')}</h3>
                    <form @submit=${this._onLoginSubmit}>
                        <capp-input ?disabled=${this._loading} label="${APP_STRINGS.getWPMLString('LoginSubView-formField-email')}" name="email" type="text"></capp-input>
                        <capp-input ?disabled=${this._loading} label="${APP_STRINGS.getWPMLString('LoginSubView-formField-password')}" name="password" type="password"></capp-input>
                        ${this._loginError?html`
                            <div class="message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${this._loginError}</span></div>
                        `:''}
                        <button class="btn-primary ${this._loading?'loading':''}">${APP_STRINGS.getWPMLString('LoginSubView-Login')}</button>
                        <a href="#reset-password" class="text-link"><span>${APP_STRINGS.getWPMLString('LoginSubView-forgotPassword')}</span></a>
                    </form>
                    <!--
                    <div class="separator-or"><hr><span>or</span><hr></div>
                    <button class="social-login-btn"><img src="${`${CDN_ASSETS_URL}/icons/google.svg`}"><span>Login with Google</span></button>
                    <button class="social-login-btn"><img src="${`${CDN_ASSETS_URL}/icons/facebook.svg`}"><span>Login with Facebook</span></button>
                    -->
                </div>
                <div class="sub-view-bottom">
                    <p>${APP_STRINGS.getWPMLString('LoginSubView-noAccount')} <a href="#signup" class="text-link"><span>${APP_STRINGS.getWPMLString('LoginSubView-signUp')}</span></a></p>
                </div>
            </div>
        `;
    }

    _onLoginSubmit(e){
        e.preventDefault();
        if(this._loading){return;}
        let data=FormHelpers.getFormDataAsObject(e.target);
        console.log('on login submit',data);
        this._loginError=null;
        this._setLoading(true);
        USER.login(data.email,data.password,this.redirectUrl);
    }

    _setLoading(value){
        this._loading=value;
        this.requestUpdate();
    }

    _onCloseButtonClick(){
        // window.history.back();
        location.href="#";
    }
    _onChatButtonClick(){
        ShowChatWindow();
    }

    close(){
        if(this._loading){return;}
        super.close();
    }
}

customElements.define('login-sub', LoginSubview);