import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {CAPPInput} from "../form-elements/CAPPInput";
import {CAPPSelect} from "../form-elements/CAPPSelect";
import {CAPPMultiselect} from "../form-elements/CAPPMultiselect";
import {CAPPDatepicker} from "../form-elements/CAPPDatepicker";
import {CAPPCheckbox} from "../form-elements/CAPPCheckbox";
import {FormHelpers} from "../../util/FormHelpers";
import {TemplateSubviewBanner, TemplateSubviewLogoHeader} from "../../../templates/subViewElements";
import {USER} from "../../core/User";
import {Validation} from "../../util/Validation";
import {FORM_MESSAGE_TYPE} from "../form-elements/FormElementBase";
import {CAPP_API} from "../../core/CAPPApi";
import {Helpers} from "../../util/Helpers";
import {CAPPPopup} from "../popups/CAPPPopup";
import {POPUP_DESKTOP_ALIGN} from "../popups/PopupBase";
import {APP_STRINGS} from "../../core/AppStrings";
import {ShowChatWindow} from "../../controllers/LivechatController";

//const LANGUAGE=Helpers.getLanguageFromCookie();
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();

export class RegistrationSubview extends SubViewBase{
    static RECAPTCHA_ADDED=false;
    static RECAPTCHA_LOADED=false;

    static MODE_TYPES={
        'STANDARD':'standard',
        'LOCAL_REGULATIONS':'localRegulations'
    }

    static REGISTRATION_MODE=RegistrationSubview.MODE_TYPES.STANDARD;

    constructor() {
        super();
        this.currentRegisterPage=0;
        this._addressLookupMode=false;
        this._commChannels=null;
        this._passwordValidationData={
            capitalLetterCheck: false,
            lengthCheck: false,
            numberCheck: false,
            passwordStrengthLabel:'',
            passwordStrength:0
        }
        this._dialingCodes=null;
        this._dialingCodesSelectData=null;
        this._countries=null;
        this._defaultCountry=null;

        this.maxDate=this._getMaxDate();
        this._phoneValid=true;
        this._tncValid=true;
        this._successView=false;
        this._tncView=false;
        this._registerError=null;
        this.bannerData=null;
        this._recaptchaWidgetId=null;


        this.loadBanner();
        this.loadRecaptcha();

        window.onRecaptchaResponse=function(token){
            console.log('recaptcha token',token);
        }
    }

    loadBanner(){
        CAPP_API.getRegistrationBanner('en').then(data=>{
            console.log(data);
            if(data.banner_background_img && data.banner_text){
                this.bannerData=data;
                this.requestUpdate();
            }
        })
    }

    loadRecaptcha(){
        if(RegistrationSubview.RECAPTCHA_ADDED){return;}
        console.log('loading recaptcha');

        let script = document.createElement('script');
        script.src=`https://www.google.com/recaptcha/api.js?render=${window.recaptchaClientSecret}`;
        document.body.appendChild(script);
        script.onload=()=>{
            console.log('recaptcha loaded');
            RegistrationSubview.RECAPTCHA_LOADED=true;
        }
        script.onerror=()=>{
            new CAPPPopup(
                APP_STRINGS.getWPMLString('Common-Error'),
                APP_STRINGS.getWPMLString('Recaptcha-Error-message'),
                [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}]
            )
        }
        document.body.appendChild(script);
        RegistrationSubview.RECAPTCHA_ADDED=true;
    }



    renderViewContent() {
        if(this._successView){
            return this._renderSuccessView();
        }else{
            return this._renderRegistrationView();
        }

    }

    _renderRegistrationView(){
        return html`
            <div class="reg-sub ${this._loading?'loading':''}">
                ${TemplateSubviewLogoHeader(this)}
                <div class="sub-view-content">
                    ${TemplateSubviewBanner(this)}
                    <div class="content-wrap">
                        ${this.currentRegisterPage==0 ? this._renderRegisterPage1() : this._renderRegisterPage2()}
                    </div>
                </div>
                ${this.currentRegisterPage==0 ? html`
                        <div class="sub-view-bottom">
                            <p>${APP_STRINGS.getWPMLString('SignUpSubView-hasExistingAccount')} <a href="#login" class="text-link"><span>${APP_STRINGS.getWPMLString('SignUpSubView-login')}</span></a></p>
                        </div>
                    `:''}
            </div>
            ${this._tncView?this._renderTNC():''}
        `;
    }

    _renderSuccessView(){
        return html`<div class="success-view">
            <img class="success-icn" alt="Success Icon" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
            <h3>${APP_STRINGS.getWPMLString('SignUpSubView-accountCreated-title')}</h3>
            <h4>${APP_STRINGS.getWPMLString('SignUpSubView-accountCreated-message')}</h4>
            <p>${unsafeHTML(APP_STRINGS.getWPMLString('SignUpSubView-accountCreated-listMessage'))}</p>
            <a class="btn-secondary continue-btn" @click=${this._onSuccessContinueBtnClick}>${APP_STRINGS.getWPMLString('SignUpSubView-accountCreated-linkToHomepage')}</a>
            
        </div>`
    }

    _renderRegisterPage1(){
        return html`
            <h3>${APP_STRINGS.getWPMLString('SignUpSubView-title')}</h3>
            <form class="reg-form" @submit=${this._onEmailCheckFormSubmit}>
                <button type="submit" disabled style="display: none" aria-hidden="true"></button>
                <capp-input ?disabled=${this._loading} label="${APP_STRINGS.getWPMLString('UserAccount-fields-email')}" name="emailAddress" type="text" @change=${this._handleInputChange}></capp-input>
                <div class="password-group">
                    <capp-input ?disabled=${this._loading} label="${APP_STRINGS.getWPMLString('UserAccount-createPassword')}" name="password" autocomplete="off" type="password" @change=${this._handleInputChange} @keyup=${this._handleInputChange}></capp-input>
                    ${this._renderPasswordValidations()}

                </div>
                <capp-input label="${APP_STRINGS.getWPMLString('SignUpSubView-SignUpCode')}" name="offlineCode" type="text" @change=${this._handleInputChange}></capp-input>
                <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('SignUpSubView-continue')}</button>
            </form>
            <!--
            <div class="separator-or"><hr><span>or</span><hr></div>
            <button class="social-login-btn"><img src="${`${CDN_ASSETS_URL}/icons/google.svg`}"><span>Sign Up with Google</span></button>
            <button class="social-login-btn"><img src="${`${CDN_ASSETS_URL}/icons/facebook.svg`}"><span>Sign Up with Facebook</span></button>
            -->
        `;
    }

    _renderTNC(){
        return html`<div class="tnc-view">
            <img class="close-btn" alt="Terms and Conditions Close Button" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onTNCCloseClick}>
            <div class="tnc-content">
                <capp-preloader></capp-preloader>
                <iframe src="${`/${LANGUAGE}/terms-conditions/`}"></iframe>
            </div>
        </div>`
    }

    _renderPasswordValidations(){
        return html`
            <div class="vcat">
                <div>${this._renderValidationIcon(this._passwordValidationData.lengthCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-characterNum')}</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.capitalLetterCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-capitalLetter')}</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.numberCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-numbers')}</span></div>
            </div>
            <capp-progress-bar value="${this._passwordValidationData.passwordStrength}" layout="slim"></capp-progress-bar>
            <div class="pw-strength"><span class="label">${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-weak')}</span><span class="value">${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-strong')}</span></div>
            
        `;
    }

    _renderValidationIcon(value){
        if(value){
            return html`<img class="valid" alt="Valid Input Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}">`;
        }else{
            return html`<img alt="Invalid Input Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}">`;
        }
    }

    _renderRegisterPage2(){
        return html`
            <h3>${APP_STRINGS.getWPMLString('SignUpSubView-SignUpContinue')}</h3>
            <form class="reg-form" @submit=${this._onCreateAccountSubmit}>
                <button type="submit" disabled style="display: none" aria-hidden="true"></button>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-firstName')}" name="firstName" type="text" @change=${this._handleInputChange}></capp-input>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-lastName')}" name="lastName" type="text" @change=${this._handleInputChange}></capp-input>
                <capp-datepicker max="${Helpers.formatDate(this.maxDate,true)}" name="dateOfBirth" monthType="MMM" label="${APP_STRINGS.getWPMLString('UserAccount-fields-dateOfBirth')}" @change=${this._handleInputChange}></capp-datepicker>
                ${this._renderPhoneGroupInput()}
                ${this._addressLookupMode?this._renderAddressLookup():''}
                ${this._renderManualAddressInput()}
                
                <capp-select name="currencyISO" required label="${APP_STRINGS.getWPMLString('UserAccount-fields-currency')}" >
                    ${this._currencyList.map((item,index)=>html`
                            <option value="${item.value}" ?selected=${index==0}>${item.label}</option>
                        `)}
                </capp-select>
                
                ${this._renderContactPreferences()}
                
                <div class="tnc-group">
                    <capp-checkbox name="tnc" label="${APP_STRINGS.getWPMLString('SignUpSubView-consentMessage')}" @change=${this._handleInputChange}></capp-checkbox>
                    <a class="text-link" href="" @click=${this._onTNCBtnClick}>${APP_STRINGS.getWPMLString('SignUpSubView-consentLinkLabel')}</a>
                </div>
                ${this._tncValid==false?html`
                        <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${APP_STRINGS.getWPMLString('SignUpSubView-consent-verificationInvalid')}</span></div>
                    `:''}
                ${this._registerError!=null?html`
                        <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${this._registerError}</span></div>
                    `:''}
                
                <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('SignUpSubView-createAccount')}</button>
            </form>
        `;
    }

    _renderAddressLookup(){
        return html`
            <capp-input class="no-mb" label="Address" name="address" placeholder="Start typing your address..." type="text" @change=${this._onAddressLookupChange}></capp-input>
            <button class="manual-address-toggle" type="button" @click=${this._onToggleAddressLookup}><span>Enter manually</span></button>
        `;
    }

    _renderManualAddressInput(){
        let addressSection;
        switch (RegistrationSubview.REGISTRATION_MODE){
            case RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS:
                addressSection=html`
                    <capp-select style="display: none;" label="${APP_STRINGS.getWPMLString('UserAccount-fields-country')}" name="countryISO" required @change=${this._handleInputChange}>
                        ${this._countries.map(country=>html`
                                    <option value="${country.countryCode}" ?selected=${this._defaultCountry&&this._defaultCountry.countryCode==country.countryCode}>${country.displayName}</option>
                                `)}
                    </capp-select>
                `;
                break;
            default:
                addressSection=html`
                    <div class="manual-address-input-group ${this._addressLookupMode?'':'active'}">
                        <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-address1')}" name="address" type="text" @change=${this._handleInputChange}></capp-input>
                        <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-address2')}" name="address2" type="text" @change=${this._handleInputChange}></capp-input>
                        <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-city')}" name="city" type="text" @change=${this._handleInputChange}></capp-input>
                        <capp-select style="z-index: 3;" label="${APP_STRINGS.getWPMLString('UserAccount-fields-country')}" name="countryISO" required @change=${this._handleInputChange}>
                            ${this._countries.map(country=>html`
                                    <option value="${country.countryCode}" ?selected=${this._defaultCountry&&this._defaultCountry.countryCode==country.countryCode}>${country.displayName}</option>
                                `)}
                        </capp-select>
                        <capp-input class="no-mb" label="${APP_STRINGS.getWPMLString('UserAccount-fields-postCode')}" name="postcode" type="text" @change=${this._handleInputChange}></capp-input>
                        <!--<button class="manual-address-toggle" type="button" @click=${this._onToggleAddressLookup}><span>Address look up</span></button>-->
                    </div>
                `;

        }
        return addressSection;
    }

    _renderPhoneGroupInput(){
        let phoneGroupSection;
        phoneGroupSection=html`  
            <div class="phone-group">
                <div class="pg-grid ${RegistrationSubview.REGISTRATION_MODE!=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS?'':'pg-rm-grid'}">
                    ${RegistrationSubview.REGISTRATION_MODE!=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS?
                    html`
                        <capp-select name="mobilePrefix" required label="${APP_STRINGS.getWPMLString('UserAccount-fields-mobilePrefix')}" >
                            ${this._dialingCodesSelectData.map(item=>html`
                            <option value="${item.value}" ?selected=${item.default}>${item.label}</option>
                        `)}
                        </capp-select>
                    `:''}
                    <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-phoneNumber')}" name="mobilePhone" type="${CAPPInput.INPUT_TYPE.NUMBER}" @change=${this._handleInputChange}></capp-input>
                </div>
                ${this._phoneValid==false?html`
                    <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-phone')}</span></div>
                `:''}
                
            </div>
        `; 
        return phoneGroupSection;
    }

    _renderContactPreferences(){
        return html`
            <div class="contact-preferences-group">
                <capp-multiselect label="${APP_STRINGS.getWPMLString('UserAccount-fields-contactPreferencesOptional')}" name="commChannels" @change=${this._onCommChannelsChange}>
                    ${this._commChannels.map(channel=>html`
                        <option value="${channel.value}" ?selected=${channel.active}>${channel.label}</option>
                    `)}
                </capp-multiselect>
            </div>
            ${this._commChannels.map(channel=>html`
                ${channel.active?html`
                    <capp-input label="${channel.label}" name="${channel.value}" placeholder="${channel.placeholderText}" type="text" value="${channel.inputVal}" @change=${this._onChannelUsernameChange}></capp-input>
                `:''}
            `)}
        `;
    }

    _initCommChannels(channelsData){
        this._commChannels=[];
        channelsData.forEach(item=>{
            this._commChannels.push({
                label:item.display,
                value:`commChannel${item.value}`,
                backendValue:item.value,
                placeholderText:item.field_display,
                active:false,
                inputVal:""
            })
        })

        this._commChannelPropertyNamesList=this._commChannels.map(item=>item.value);
    }
    _initCountries(data){
        this._dialingCodes=data.DialingCodes;
        this._countries=data.Countries;

        for(let i=0; i<this._countries.length; i++){
            if(this._countries[i].default==1){
                this._defaultCountry=this._countries[i];
                break;
            }
        }

        this._dialingCodesSelectData=[];
        if(RegistrationSubview.REGISTRATION_MODE==RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS){
            this._dialingCodesSelectData.push(
                {label:"-",value:"",default:true}
            );
        }

        this._dialingCodes.forEach(dialingCode=>{
            let dcSelect={label:`+${dialingCode}`,value:dialingCode,default:false}
            if(RegistrationSubview.REGISTRATION_MODE==RegistrationSubview.MODE_TYPES.STANDARD
                && this._defaultCountry.diallingCode==dialingCode
            ){
                dcSelect.default=true;
            }
            this._dialingCodesSelectData.push(dcSelect);
        })

        this._currencies=data.Currencies.Currency;


        this._currencyList=[];
        let defaultCurrency;
        let cryptoCurrencies=[];
        let otherCurrencies=[];
        data.Currencies.Currency.forEach(item=>{
            let listItem={
                label:`${item.currencyISODisplay} ${item.CurrencySymbol}`,
                value:item.CurrencyISO
            }
            if(item.isCrypto==true){
                cryptoCurrencies.push(listItem);
            }else{
                if(this._defaultCountry.currencies==item.CurrencyISO){
                    defaultCurrency=item;
                }else{
                    otherCurrencies.push(listItem);
                }
            }

        })

        if(defaultCurrency){
            console.log(RegistrationSubview.REGISTRATION_MODE);

            const label = RegistrationSubview.REGISTRATION_MODE!=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS ? 
                `${defaultCurrency.currencyISODisplay} ${defaultCurrency.CurrencySymbol}`:
                `${APP_STRINGS.getWPMLString('UserAccount-fields-currency-defaultLabel')}: ${defaultCurrency.currencyISODisplay} ${defaultCurrency.CurrencySymbol}`;

            this._currencyList.push({
                label:label,
                value:defaultCurrency.CurrencyISO
            });
        }
        cryptoCurrencies.forEach(item=>this._currencyList.push(item));

        if(RegistrationSubview.REGISTRATION_MODE!=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS) {
            otherCurrencies.forEach(item=>this._currencyList.push(item));
        }


        console.log("this._dialingCodesSelectData",this._dialingCodesSelectData);
        console.log("this._currencyList",this._currencyList);

    }


    _onEmailCheckFormSubmit(e){
        e.preventDefault();


        let emailElement=this.renderRoot.querySelector('capp-input[name="emailAddress"]');
        this._validateInputElement(emailElement);

        let pwElement=this.renderRoot.querySelector('capp-input[name="password"]');
        this._validateInputElement(pwElement);
        if(this._passwordValidationData.valid!=true){
            pwElement.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-password');
            pwElement.messageType=FORM_MESSAGE_TYPE.ERROR;
        }

        let signUpCodeElement=this.renderRoot.querySelector('capp-input[name="offlineCode"]');

        if(!emailElement.valid || !pwElement.valid){
            return;
        }

        this._loginFields=FormHelpers.getFormDataAsObject(e.target);
        this._setLoading(true);

        let p=[];
        p.push(USER.checkIfEmailExists(this._loginFields.emailAddress));

        if(this._loginFields.offlineCode.length>0){
            p.push(USER.checkSignUpCode(this._loginFields.offlineCode))
        }

        Promise.all(p).then(results=>{
            let allchecksValid=true;
            if(results[0].error){
                emailElement.message=results[0].error.text;
                emailElement.messageType=FORM_MESSAGE_TYPE.ERROR;
                allchecksValid=false;
            }else if(results[0].Exists){
                emailElement.message=APP_STRINGS.getWPMLString('SignUpSubView-existingUserError-message');
                emailElement.messageType=FORM_MESSAGE_TYPE.ERROR;
                allchecksValid=false;
            }

            if(results.length==2 && results[1].error){
                signUpCodeElement.message=results[1].error.text;
                signUpCodeElement.messageType=FORM_MESSAGE_TYPE.ERROR;
                allchecksValid=false;
            }

            if(allchecksValid){
                this._loadCountryCodesInfo();
            }else{
                this._setLoading(false);
            }
        })

/*
        USER.checkIfEmailExists(this._loginFields.email).then(data=>{
            if(data.Exists){
                this._setLoading(false);
                emailElement.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-emailExists');
                emailElement.messageType=FORM_MESSAGE_TYPE.ERROR;
            }else{

            }
        })*/

        //this._loadCountryCodesInfo();
    }

    _loadCountryCodesInfo(){


        Promise.all([CAPP_API.getCountryCodeInfo(true),CAPP_API.getSocialMediaList()]).then(data=>{
            console.log('step 2 data',data);
            this._initCountries(data[0]);
            this._initCommChannels(data[1]);

            this._setLoading(false);
            this.currentRegisterPage=1;

        })

    }

    _onCreateAccountSubmit(e){
        e.preventDefault();

        let formElements=FormHelpers.getFormElements(e.target);
        let valid=true;
        formElements.forEach(el=>{
            let res=this._validateInputElement(el);
            valid=valid&res;
        })

        if(!valid){
            this.requestUpdate();
            return;
        }

        let data=this._collectSignUpFormData(e.target);

        console.log('signup data',data);

        this._registerError=null;
        this._setLoading(true);

        window.grecaptcha.ready(()=> {
            window.grecaptcha.execute(window.recaptchaClientSecret, {action: 'submit'}).then((token)=> {//window.SITE_KEY
                data['g-recaptcha-response']=token;
                USER.register(data).then(data=>{
                    if(data.error){
                        this._registerError=data.error.text;
                    }else{
                        this._successView=true;
                        this.languageRedirect=data.languageRedirect;
                    }
                    this._setLoading(false);
                })
            });
        });


    }

    _collectSignUpFormData(form){
        let formData=FormHelpers.getFormDataAsObject(form);
        let dataToSend=Object.assign({},formData, this._loginFields);


        delete dataToSend.commChannels;
        delete dataToSend.tnc;
        dataToSend.activateAccount=true;
        dataToSend.loginOnSuccess=true;


        let channelsData={};
        let chIndex=0;
        this._commChannels.forEach(ch=>{
            if(ch.active){
                channelsData[`socialMediaArray[${chIndex}][type]`]=ch.backendValue;
                channelsData[`socialMediaArray[${chIndex}][value]`]=ch.inputVal;
                delete dataToSend[ch.value];
                chIndex++;
            }
        })
        dataToSend=Object.assign(dataToSend,channelsData);


        return dataToSend;
    }

    _onToggleAddressLookup(){
        this._addressLookupMode=!this._addressLookupMode;
        this.requestUpdate();
    }

    _onAddressLookupChange(){
        //todo implement address lookup and then remove this
        this._addressLookupMode=false;
        this.requestUpdate();
    }

    _onCommChannelsChange(e){
        console.log('com channels list',this._commChannels)
        console.log('com channel change',e.detail)
        this._commChannels.forEach(item=>{
            item.active=e.detail.indexOf(item.value)>=0;
        })
        this.requestUpdate();
    }

    _onChannelUsernameChange(e){

        for(let i=0; i<this._commChannels.length; i++){
            if(this._commChannels[i].value==e.target.name){
                this._commChannels[i].inputVal=e.detail;
                break;
            }
        }
    }

    _handleInputChange(e){
        this._validateInputElement(e.target);
    }

    _validateInputElement(inputEl){
        let valid=true;
        let r;

        if(this._commChannelPropertyNamesList){
            let commChIndex=this._commChannelPropertyNamesList.indexOf(inputEl.name);
            if(commChIndex>=0){
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-commChannelUsername').replace('{{channel}}',this._commChannels[commChIndex].label);
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
            }
        }


        switch (inputEl.name){
            case 'emailAddress':
                r=Validation.validateEmail(inputEl.value);
                valid=r.valid;
                if(r.valid){
                    inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-email');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
            case 'password':
                r=Validation.validatePassword(inputEl.value);
                valid=r.valid;
                if(r.valid){
                    inputEl.valid=true;
                }else{
                    inputEl.valid=false;
                }
                inputEl.message=null;
                this._passwordValidationData=r;
                this.requestUpdate();
                break;
            case 'firstName':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-firstName');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
            case 'lastName':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-lastName');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
            case 'dateOfBirth':


                if(inputEl.value){

                    try{
                        let dobDate=new Date(inputEl.value);
                        let target=new Date();
                        target.setFullYear(target.getFullYear()-18);

                        if(target<dobDate){
                            throw new Error();
                        }else{
                            valid=inputEl.valid=true;
                            inputEl.message=null;
                        }
                    }catch (e){
                        valid=inputEl.valid=false;
                        inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-dateOfBirth-atLeast18');
                        inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    }

                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-dateOfBirth');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
            case 'mobilePhone':
                if(inputEl.value && inputEl.value.toString().length>=5 && inputEl.value.toString().length<=11){
                    this._phoneValid=true;
                    valid=inputEl.valid=true;
                }else{
                    this._phoneValid=false;
                    valid=inputEl.valid=false;
                }

                break;
            case 'address':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-address');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'city':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-city');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'countryISO':
                if(inputEl.value){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-country');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'postcode':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-postCode');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'tnc':
                valid=inputEl.value;
                this._tncValid=inputEl.value;
                break;
            case 'offlineCode':
                valid=inputEl.valid=true;
                inputEl.message=null;
                break;
            default:
                console.log('validate',inputEl);
        }
        this.requestUpdate();
        return valid;
    }

    _setLoading(value){
        this._loading=value;
        this.requestUpdate();
    }

    _getMaxDate(){
        let date=new Date();
        date.setFullYear(date.getFullYear()-18);
        return date;
    }

    _onTNCBtnClick(e){
        e.preventDefault();
        this._tncView=true;
        this.requestUpdate();
    }

    _onTNCCloseClick(){
        this._tncView=false;
        this.requestUpdate();
    }


    _onCloseButtonClick(){
        // window.history.back();
        location.href="#";
    }

    _onChatButtonClick(){
        ShowChatWindow();
    }

    _onSuccessContinueBtnClick(e){
        e.target.classList.add("loading");
        window.location.href=window.location.origin+'/'+this.languageRedirect+'/';
    }

    close(){
        super.close();
    }


}

customElements.define('registration-sub', RegistrationSubview);