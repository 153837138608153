import {APP_STRINGS} from '../core/AppStrings'
const stringEntropy = require('fast-password-entropy');

export const Validation={
    validateEmail:function(value){
        let result = {};
        let re = /^([\w-+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;


        if(re.test(value)){
            result.valid =true;
        }else{
            result.valid =false;
            result.message = "This is not a valid email address";
        }

        return result;
    },

    validatePassword:function(value){
        let lengthCheck=false;
        let capitalLetterCheck=false;
        let numberCheck=false;
        if(value.length>=8){
            lengthCheck=true;
        }
        if(value.toLowerCase()!=value){
            capitalLetterCheck=true;
        }
        let numbers=["1","2","3","4","5","6","7","8","9","0"];
        let numbersUsed=0;
        for(let i=0; i<value.length; i++){
            if(numbers.indexOf(value[i])>=0){
                numbersUsed++;
            }
        }
        if(numbersUsed>0){
            numberCheck=true;
        }

        let strengthValuePercent=Math.min(1,stringEntropy(value)/200);


        let res={
            valid:lengthCheck&&capitalLetterCheck&&numberCheck,
            lengthCheck:lengthCheck,
            capitalLetterCheck:capitalLetterCheck,
            numberCheck:numberCheck,
            passwordStrength:strengthValuePercent*100,
        }

        return res;
    },

    validateBitcoinAddress:function(value){
        console.log('validate',value);
        let valid;
        if(value.match(/^(1|3)/) != null && value.length > 24 && value.length < 36){
            /*
                    P2PKH which begin with the number 1, eg: 1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2.
                    P2SH type starting with the number 3, eg: 3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy.
                    Bech32 type starting with bc1, eg: bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq
                */

            valid=true;
        }
        else if(value.match(/^(bc1)/) != null && (value.length == 42 || value.length == 62)){
            //Mainnet P2WPKH: 42 characters (version 0 witness program)
            //Mainnet P2WSH: 62 characters (version 0 witness program)
            //NOTE: In future newer versions may arise to a maximum of 90 characters.
            valid=true;
        }else{
            valid=false;
        }
        console.log('valid',valid);
        return {valid:valid};
    },

    validateDepositForm:function(value){
        let result = {};
        let re = /^[\w\d\s!"#$%&'()*+,-.\/:;<=>?@[\\\]^_`{|}~]*$/;

        if(re.test(value)){
            result.valid =true;
        }else{
            result.valid =false;
        }

        return result;
    }

}