import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element"
import {USER} from "../../core/User"
import {FormHelpers} from "../../util/FormHelpers";
import {Validation} from "../../util/Validation";
import {FORM_MESSAGE_TYPE} from "../form-elements/FormElementBase";
import {APP_STRINGS} from "../../core/AppStrings";

export class ResetPasswordSubView extends SubViewBase{
    static MODE={
        FORGOT:'forgot',
        LOCKED:'locked'
    }

    constructor(mode) {
        super();
        this._loading=false;
        this._successView=false;
        this._mode=mode?mode:ResetPasswordSubView.MODE.FORGOT;
        this._title="";
        this._message="";

    }

    initPopupText(){
        switch (this._mode){
            case ResetPasswordSubView.MODE.FORGOT:
                this._title=APP_STRINGS.getWPMLString('ResetPasswordSubView-forgotPassword-title');
                this._message=APP_STRINGS.getWPMLString('ResetPasswordSubView-forgotPassword-message');
                break;
            case ResetPasswordSubView.MODE.LOCKED:
                this._title=APP_STRINGS.getWPMLString('ResetPasswordSubView-lockedAccount-title');
                this._message=APP_STRINGS.getWPMLString('ResetPasswordSubView-lockedAccount-message');
                break;
        }
    }

    renderViewContent() {
        this.initPopupText();
        return html`
            <div class="forgot-password-sub ${this._loading?'loading':''}">
                ${this.renderSubviewHeader()}
                <div class="sub-view-content">
                    <h3>${this._title}</h3>
                    <p>${this._message}</p>
                    <form @submit=${this._onFormSubmit}>
                        <capp-input label="${APP_STRINGS.getWPMLString('ResetPasswordSubView-emailInput-label')}" name="email" type="text"></capp-input>
                        <button class="btn-primary ${this._loading?'loading':''}" type="submit">${APP_STRINGS.getWPMLString('ResetPasswordSubView-submitBtn-label')}</button>
                    </form>
                </div>
                ${this._renderSuccessView()}
            </div>
        `;
    }

    renderSubviewHeader(){
        return html`
            <div class="sw-header-simple">
                <img class="close-btn" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onCloseButtonClick}>
                <!--<img class="close-btn" src="${`${CDN_ASSETS_URL}/icons/livechat.svg`}" >-->
            </div>
        `;
    }

    _renderSuccessView(){
        if(this._successView==false){return '';}
        return html`
            <div class="success-view">
                <img class="success-icn" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
                <h3>${APP_STRINGS.getWPMLString('ResetPasswordSubView-success-title')}</h3>
                <p>${APP_STRINGS.getWPMLString('ResetPasswordSubView-success-message')}</p>
                <a class="btn-secondary continue-btn" @click=${this._onContinueButtonClick}>${APP_STRINGS.getWPMLString('Common-OK')}</a>
            </div>
        `;
    }

    _onFormSubmit(e){
        e.preventDefault();
        if(this._validateEmail()==false){
            return;
        }

        this._setLoading(true);

        let formData=FormHelpers.getFormDataAsObject(e.target);
        USER.resetPassword(formData.email).then(data=>{
            this._setLoading(false);
            if(data.error){
                let inputEl=this.renderRoot.querySelector('capp-input[name="email"]');
                inputEl.valid=false;
                inputEl.message=data.error.text;
                inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
            }else{
                this._successView=true;
            }

            this.requestUpdate();
        })

    }

    _setLoading(value){
        this._loading=value;
        this.requestUpdate();
    }

    _onCloseButtonClick(){
        location.href="#";
    }

    _onContinueButtonClick(){
        location.replace("#");
    }

    _validateEmail(){
        let inputEl=this.renderRoot.querySelector('capp-input[name="email"]')
        let valid;
        let r;
        r=Validation.validateEmail(inputEl.value);
        valid=r.valid;
        if(r.valid){
            inputEl.valid=true;
            inputEl.message=null;
        }else{
            inputEl.valid=false;
            inputEl.message=APP_STRINGS.getWPMLString('ResetPasswordSubView-emailInput-invalid');
            inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
        }
        this.requestUpdate();
        return valid;
    }
}

customElements.define('reset-password-sub',ResetPasswordSubView);