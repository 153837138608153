import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "./CAPPApi";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

class User extends EventEmitter{
    constructor() {
        super();
        this.isLoggedIn=false;
        this.data=null;
        this.sessionKey=null;
        this.depositBonuses = null;
        this.manualBonuses = null
        this.activeBonuses = null;
        this.loginBonuses = null;
        this.topActiveBonus = null;
        this.registerData=null;
        this.activatedDepositBonus = null;
        this._init();
    }

    _init(){

        if(Helpers.getCookie('is_logged_in')==1){
            this._processLoggedInUserData();

        }else{
            console.log("USER not logged in");
        }
    }

    _processLoggedInUserData(){
        this.sessionKey=Helpers.getCookie('sessionKey');

        let storageData = Helpers.getStorage('userData', 'local', window.userDataExpiresCheck, () => {
            console.log('callbackOnStorageExpired');
            this.updateUserData();
        });

        console.log(storageData, "userData");

        if(storageData){
            console.log("***LOCal storageitem  exists")
            this.isLoggedIn=true;
        }else{
            console.log("***LOCal storage item is missing")
            this.isLoggedIn=false;
            Helpers.deleteCookie("is_logged_in");
            Helpers.deleteCookie("sessionKey");
            return;
        }

        //todo include error checking and load user data if for some reason session storage data doesnt exist
        this.data=JSON.parse(storageData);
        this._parseUserData();
        this.updatePlayerBalance();
    }

    get phoneNumberVerified(){
        console.log('this data', this.data);
        let phoneVerifiedAttr=this.data.Attributes.PlayerAttribute.find(item=>item.AttrName=="IsMobilePhoneOtpValidated");
        if(phoneVerifiedAttr){
            return phoneVerifiedAttr.AttrValue=="1";
        }else{
            return false;
        }
    }

    login(username, password,redirectUrl){
        this._invalidateExistingDepositData();

        return CAPP_API.login(username,password)
            .then(data=>{
                console.log("Login response",data);
                if(data.error){
                    //do something with error here?
                    this.emit("onLoginError",{error:data.error.text,errorID:data.error.errorID});
                }else{
                    this.sessionKey=data.SessionKey;
                    this.data=data.playerDetails;
                    Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                    this._parseUserData();
                    this.isLoggedIn=true;
                    console.log(`User has logged in V2`,this.data);
                    //this.emit("onLoginStateChange",{loggedIn:true});
                    //todo maybe better to handle in event listener rather than here?
                    if(redirectUrl){
                        window.location.replace(redirectUrl);
                    }else{
                    
                        window.location.replace(Helpers.replaceLinkLanguage(window.location.href.replace(window.location.hash, ''),data.languageRedirect));
                        //window.location.reload();
                    }

                }
                return data;
            })
            /*todo handle unknown errors
            .catch(reason => {
                return reason;
            })*/
    }

    loginBySessionKey(sessionKey){
        this._invalidateExistingDepositData();

        return CAPP_API.loginBySessionKey(sessionKey)
            .then(data=>{
                console.log("Login response",data);
                if(data && !data.error){
                    this.sessionKey=data.SessionKey;
                    this.data=data.playerDetails;
                    Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                    this._parseUserData();
                    this.isLoggedIn=true;
                    console.log(`User has logged in V2 by sessionKey`,this.data);
                    this.emit("onLoginStateChange",{loggedIn:true});
                }
                return data;
            })
            /*todo handle unknown errors
            .catch(reason => {
                return reason;
            })*/
    }

    loginAsPlayer(username, password, verificationKey, redirectUrl){
        this._invalidateExistingDepositData();

        return CAPP_API.loginAsPlayer(username, password, verificationKey)
            .then(data=>{
                console.log("Login As Player response",data);
                if(data.error){
                    //do something with error here?
                    //this.emit("onLoginError",{error:data.error.text});
                }else{
                    this.sessionKey=data.SessionKey;
                    this.data=data.playerDetails;
                    Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                    this._parseUserData();
                    this.isLoggedIn=true;
                    console.log(`User has logged in V2`,this.data);
                    //this.emit("onLoginStateChange",{loggedIn:true});
                    //todo maybe better to handle in event listener rather than here?
                    if(redirectUrl){
                        window.location.replace(redirectUrl);
                    }else{
                        window.location.reload();
                    }

                }
                return data;
            })
            /*todo handle unknown errors
            .catch(reason => {
                return reason;
            })*/
    }

    _parseUserData(){
        CURRENCY_HELPERS.isCrypto=this.data.isCryptoWallet;
        CURRENCY_HELPERS.isNonStableCrypto=this.data.isNonStableCryptoWallet;

        this.data.CurrentBalance=parseFloat(this.data.CurrentBalance);
        this.data.CurrentBalanceNumberDisaply=CURRENCY_HELPERS.formatCurrency(this.data.CurrentBalance);
        this.data.CurrentBalanceDisaply=CURRENCY_HELPERS.getCurrencySymbolString(this.data.CurrentBalanceNumberDisaply);

        this.data.CurrentBonusBalance=parseFloat(this.data.CurrentBonusBalance);
        this.data.CurrentBonusBalanceDisaply=CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(this.data.CurrentBonusBalance));

        this.data.CurrentLoyaltyPoints=parseInt(this.data.CurrentLoyaltyPoints);
        this.data.CurrentLoyaltyPointsDisplay=Helpers.formatLoyaltyPoints(this.data.CurrentLoyaltyPoints);

        let addresses=this.data.Address1.split(';');
        this.data.Address1Display=addresses[0];
        this.data.Address2Display=addresses[1];

        this.data.LoyaltyProgressPercentageDisplay=parseFloat(this.data.LoyaltyProgressPercentage)*100;

    }

    logout(skipApi){
        console.log("something");

        if(!skipApi){
            CAPP_API.logout(this.sessionKey).then(data=>{
                console.log("Logout response",data);
            })
        }
        //we are not waiting for response from this api call, there are also still errors on session expired and so on


        //lets clean this up ourselves since backend sometimes leaves the mess when session expires
        Helpers.deleteCookie("is_logged_in");
        Helpers.deleteCookie("sessionKey");
        Helpers.deleteCookie("PHPSESSID");
        this.isLoggedIn=false;
        this.sessionKey=null;
        Helpers.removeStorage('userData', 'local', window.userDataExpiresCheck);
        this.data=null;
        this._invalidateExistingDepositData();
        this.emit("onLoginStateChange",{loggedIn:false});
    }

    resetPassword(emailAddress){
        return CAPP_API.resetPassword(emailAddress)
            .then(data=>{
                console.log("ForgotPassword response",data);
                return data
        })
    }

    getVerificationData(){

        return new Promise((resolve,reject)=> {
            let requests = [
                CAPP_API.getAccessStatus(this.sessionKey),
                CAPP_API.getPaymentAccounts(USER.sessionKey, USER.data.LangaugeISO),
                this.updateUserData()
            ];

            Promise.all(requests)
                .then(results=>{
                    //todo parse and prepare all the data
                    console.log("results",results);
                    if(results[0].error){
                        reject(results[0].error);
                    }else if (results[1].error){
                        reject(results[1].error);
                    }

                    let d={
                        basicDocs:this._processBasicDocsData(results[0]),
                        accountsVerification:this._processAccountsVerifiactionData(results[1])
                    }

                    resolve(d);
                })
                .catch(e=>{
                    console.log(e);
                });

        });
    }

    _processBasicDocsData(data){
        let accessData={}
        if(data.KycChecked){
            accessData.proofOfIdentification="approved";
            accessData.proofOfAddress="approved";
        }else{
            if(this.checkForDocumentPendingStatus("ID")){
                accessData.proofOfIdentification="pending";
            }else{
                accessData.proofOfIdentification="required";
            }
            if(this.checkForDocumentPendingStatus("POA")){
                accessData.proofOfAddress="pending";
            }else{
                accessData.proofOfAddress="required";
            }
        }
        if(data.AccountActivated){
            accessData.accountActivated="approved";
        }else{
            accessData.accountActivated="required";
        }

        if(USER.data.IsMobilePhoneOtpValidated) {
            accessData.mobileNumberValidated="approved";
        }
        else {
            accessData.mobileNumberValidated="required";
        }

        return accessData;
    }
    _processAccountsVerifiactionData(data){
        let accountsData=[];
        if(data.PaymentAccounts && data.PaymentAccounts.TransactionPaymentAccount){
            let accounts=data.PaymentAccounts.TransactionPaymentAccount;
            accounts.forEach(account=>{
                if (account.CappPaymentVerification == 1) {
                    return;
                }
                if(account.KYCChecked){
                    account.status="approved";
                }else{
                    let pending=this.checkForDocumentPendingStatus(account.PaymentAccountID);
                    account.status=pending?"pending":"required";
                }
                if(account.CappPaymentCategorySlug=="credit-debit-cards"){
                    if(isNaN(parseInt(account.AccountReference[0]))){
                        account.docTitle = "Credit card: "+ account.AccountReference;
                    }else{
                        account.docTitle = account.AccountReference.replace(",", "## #### ");
                        account.docTitle = account.docTitle.substr(0, 4) + " " + account.docTitle.substr(4);
                        account.docTitle = "Credit card: "+ account.docTitle;
                    }

                }else{
                    account.docTitle = account.CappPaymentDisplayName+": "+account.AccountReference;
                }

                accountsData.push(account);
            })
        }
        return accountsData;
    }

    checkForDocumentPendingStatus(docId){
        var isPending=false;
        if (this.data && this.data.Attributes && this.data.Attributes.PlayerAttribute) {
            this.data.Attributes.PlayerAttribute.forEach(function (attr) {
                if ((attr.AttrName.indexOf("KYC_" + docId)>=0) && attr.AttrValue == "Pending") {
                    isPending=true;
                }
            });
        }

        return isPending;
    };

    updateUserData(){
        if(!this.sessionKey){return Promise.resolve()}
        console.log('updateUserData called');
        return CAPP_API.loadUser(this.sessionKey).then(data=>{
            if(data.error){
                //todo handle user data error
            }else{
                this.data=Object.assign(this.data,data);
                this._parseUserData();
                Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                this.emit("onUserDataUpdate");
            }
        });
    }

    updatePlayerBalance(){
        if((!this.isLoggedIn) || (typeof CAPP_API) === 'undefined'){return Promise.resolve()}
        return CAPP_API.getPlayerBalance(this.sessionKey,this.data.LanguageISO)
            .then(data=>{
                console.log("balance data",data);
                if(data.error){

                }else{
                    this.data.CurrentBalance=parseFloat(data.CurrentTotalBalance);
                    this.data.CurrentRealBalance = parseFloat(data.CurrentRealBalance);
                    this.data.CurrentBonusBalance = parseFloat(data.CurrentBonusBalance);
                    this.data.CurrentLoyaltyPoints = parseInt(data.CurrentLoyaltyPoints);
                    this._parseUserData();
                    Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                    this.emit("onUserDataUpdate");
                    this.emit("onUserBalanceUpdate");
                }
                return data;
            });
    }

    changePassword(oldPassword, newPassword){
        return CAPP_API.changePassword(oldPassword, newPassword, this.sessionKey)
        .then(data=>{
            console.log("changePassword response",data);
            if(data.error){
                return data;
            }else{
                //success
                return CAPP_API.loadUser(this.sessionKey);
            }
        })
        .then(data=>{
            if(data.error){
                //todo handle user data loading error
            }else{
            }
            return data;
        })
    }

    resendActivationEmail(){
        return CAPP_API.resendActivationCode(this.sessionKey)
            .then(data=>{
                //dont think we need to do anything here
                return data;
            })
    }

    updatePlayerInfo(data){
        if (data.address) {
            if(data.address2){
                data.address=`${data.address};${data.address2}`;
           }else{
                data.address=`${data.address}`;
                delete data.address2;
           }
        }

        if (!data.sessionKey) {
            data.sessionKey = USER.sessionKey;
        }

        return CAPP_API.updatePlayerInfo(data)
            .then(data=>{
                console.log("updatePlayerInfo response",data);
                return data
        })
    }

    register(data){
        if(data.address){
            data.address=`${data.address};${data.address2}`;
            delete data.address2;
        }


        return CAPP_API.register(data)
        .then(data=>{
            if(data.error){

            }else{
                this.sessionKey=data.SessionKey;
                this.data=data.playerDetails;
                Helpers.setStorage('userData', JSON.stringify(this.data), 'local', window.userDataTtl);
                this._parseUserData();
                this.isLoggedIn=true;
                console.log(`Register response - User has logged in V2`,this.data);
            }

            return data;
    })
    }

    registerConfirmLocation(clientId, signature, answer){
        return CAPP_API.registerConfirmLocation(clientId,signature,answer)
            .then(data=>{
                console.log("register response",data);
                return data
            });
    }

    checkIfEmailExists(emailAddress){
        return CAPP_API.checkEmailExist(emailAddress)
        .then(data=>{
            console.log("checkEmailExist response",data);
            return data
        })
    }

    checkSignUpCode(SignupCode){
        return CAPP_API.checkSignUpCode(SignupCode)
        .then(data=>{
            console.log("checkSignUpCode response",data);
            return data
        })
    }

    getRegisterDataList(){
        let requests=[
            CAPP_API.getCountryCodeInfo(true),
            CAPP_API.getCitizenshipList(),
            CAPP_API.getSecretQuestionList()
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                countryCodeInfo     : data[0],
                citizenshipList      : data[1],
                secretQuestionList  : data[2],
            }
            // this.registerData = formattedData;
            return formattedData;
        })
    }

    getRGLimitData(){
        let requests=[
            CAPP_API.transactionGetAllDepositLimit(this.sessionKey),
            CAPP_API.gameLimitGetAll(this.sessionKey),
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                depositLimitList   : data[0],
                gameLimitList      : data[1],
            }
            return formattedData;
        })
    }

    setDepositLimit(intervalType, amount){
        return CAPP_API.transactionSetDepositLimit(intervalType, amount*100, this.sessionKey)
        .then(data=>{
            console.log("setDepositLimit response",data);
            return data
        })
    }

    removeDepositLimit(intervalType){
        return CAPP_API.transactionRemoveDepositLimit(intervalType, this.sessionKey)
        .then(data=>{
            console.log("removeDepositLimit response",data);
            return data
        })
    }

    setBetLimit(intervalType, amount){
        return CAPP_API.setBetLimit(intervalType, amount*100, this.sessionKey)
        .then(data=>{
            console.log("setBetLimit response",data);
            return data
        })
    }

    removeBetLimit(intervalType){
        return CAPP_API.removeBetLimit(intervalType, this.sessionKey)
            .then(data=>{
                console.log("removeBetLimit response",data);
                return data
            })
    }

    setLossLimit(intervalType, amount){
        return CAPP_API.setLossLimit(intervalType, amount*100, this.sessionKey)
        .then(data=>{
            console.log("setLossLimit response",data);
            return data
        })
    }

    removeLossLimit(intervalType){
        return CAPP_API.removeLossLimit(intervalType, this.sessionKey)
            .then(data=>{
                console.log("removeLossLimit response",data);
                return data
            })
    }

    setTimeLimit(timeDuration){
        return CAPP_API.setTimeLimit(timeDuration, this.sessionKey)
        .then(data=>{
            console.log("setTimeLimit response",data);
            return data
        })
    }

    setSelfExclude(minutes, isIndefinite, reason){
        return CAPP_API.setSelfExclude(minutes, isIndefinite, reason, this.sessionKey)
        .then(data=>{
            console.log("setSelfExclude response",data);
            return data
        })
    }
    
    getBonusAllData(){
        let requests=[
            CAPP_API.getAvailableBonuses(this.sessionKey),
            CAPP_API.getActiveBonuses(this.sessionKey),
        ];

        return Promise.all(requests).then(data=>{
            var formattedData =  {
                availableBonusList   : data[0],
                activeBonusList      : data[1],
            }
            this._processDepositBonusesList(formattedData)
            this._processManualBonus(formattedData)
            this._processActiveBonus(formattedData)

            let bonusData = {
                depositBonuses: this.depositBonuses,
                loginBonuses: this.loginBonuses,
                manualBonuses: this.manualBonuses,
                activeBonuses: this.activeBonuses,
                topActiveBonus: this.topActiveBonus
            };

            return bonusData;
        })
    }

    uploadBasicDocument(docId,file){
        let data = new FormData();
        data.append('username', this.data.Username);
        data.append('ImageFile', file);
        data.append('paymentMethod', 0);
        data.append('AccountID', this.data.ClientID);
        data.append('idDocument', docId);
        data.append('accountReference', "");
        return this._submitVerificationDocument(data)
            .then(data=>{
                console.log("UPLOAD RESPONSE",data);
                return data;
            })
    }

    uploadAccountDocument(accountId,accountReference,file){
        let data = new FormData();
        data.append('username', this.data.Username);
        data.append('ImageFile', file);
        data.append('paymentMethod', accountId);
        data.append('AccountID', this.data.ClientID);
        data.append('idDocument', accountReference);
        data.append('accountReference', accountReference);
        return this._submitVerificationDocument(data)
            .then(data=>{
                console.log("UPLOAD RESPONSE",data);
                return data;
            })
    }

    uploadAdditionalDocument(docId,file){
        let data = new FormData();
        data.append('username', this.data.Username);
        data.append('ImageFile', file);
        data.append('paymentMethod', 0);
        data.append('AccountID', this.data.ClientID);
        data.append('idDocument', docId);
        data.append('accountReference', "");
        data.append('AdditionalDocType', "Other");
        return this._submitVerificationDocument(data)
            .then(data=>{
                console.log("UPLOAD RESPONSE",data);
                return data;
            })
    }

    _submitVerificationDocument(formData){
        return CAPP_API.uploadDocument(formData);
    }

    _processDepositBonusesList(bonusList){

        try{
            let depositBonusList = [];
            if(bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit){
                if(bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit.length > 0){
                    depositBonusList = bonusList.availableBonusList.TransactionGetApplicableDepositBonusesResult.BonusRules.BonusRuleDeposit;
                    for (const [key, bonusInfo] of Object.entries(depositBonusList)) {
                        this._processDepositBonus(depositBonusList[key]);

                    }
                    this.depositBonuses = depositBonusList;
                }else{
                    this.depositBonuses = null;
                }
            }
        }catch (e){
            this.depositBonuses = null;
        }

    }

    _processDepositBonus(bonus){

        bonus['displayData'] = []
        bonus['displayData']['minDeposit'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonus.BonusMinDepositAmount));
        bonus['displayData']['matchAmount'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonus.BonusPercentageMaxAmount));
        bonus['displayData']['fixedAmount'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonus.BonusFixedAmount));
        bonus['displayData']['maxCashOut'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonus.MaxCashOut));

        if (bonus.BaseRule && bonus.BaseRule.WagerRequirementMultiplier){
            bonus['displayData']['wageringReq'] = parseInt(bonus.BaseRule.WagerRequirementMultiplier)+'x';
        }

        try{
            bonus['displayData']['expiryDate'] = Helpers.formatDate(bonus.BaseRule.ExpiryDate);
        }catch(e){
            bonus['displayData']['expiryDate']="";
        }

        bonus['displayData']['maxBet'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonus.cappMaxBet));
        bonus['displayData']['buttonName'] = 'ACTIVATE';
        bonus['displayData']['type'] = 'deposit';
    }


    _processManualBonus(bonusList){
        try{
            // var manualBonusList = [];
            if(bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth){
                if(bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth.length > 0){
                    let manualBonusList = {'login':null, 'manual':null};
                   ;
                    for (const [key, bonusInfo] of Object.entries( bonusList.availableBonusList.PlayerGetPreAuthBonusesResult.Bonuses.BonusAwardedPreAuth)) {
                        let bonusType = 'manual';
                        if(bonusInfo?.BonusTypeName === 'Login'){
                            bonusType = 'login';
                        }
                        bonusInfo['displayData'] = []
                        bonusInfo['displayData']['bonusAmount'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.BonusAmount));
                        if (bonusInfo.WagerRequirementMultiplier){
                            bonusInfo['displayData']['wageringReq'] = parseInt(bonusInfo.WagerRequirementMultiplier)+'x';
                        }

                        var expiryDate = ''
                        if(bonusInfo.ExpiryDateFixed){
                            expiryDate = bonusInfo.ExpiryDateFixed;
                        }else{
                            if(bonusInfo.ExpiryDate){
                                expiryDate = bonusInfo.ExpiryDate
                            }else{
                                expiryDate = ExpiryDaysFromAwarding
                            }
                        }

                        try{
                            bonusInfo['displayData']['expiryDate'] = Helpers.formatDate(expiryDate);
                        }catch(e){
                            bonusInfo['displayData']['expiryDate']="";
                        }

                        bonusInfo['displayData']['maxBet'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.cappMaxBet));
                        bonusInfo['displayData']['maxCashOut'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.MaxCashOut));
                        bonusInfo['displayData']['buttonName'] = 'CLAIM'
                        bonusInfo['displayData']['type'] = 'manual';
                        // Ensure the manualBonusList[bonusType] is an array before pushing
                        if (!Array.isArray(manualBonusList[bonusType])) {
                            manualBonusList[bonusType] = [];
                        }
                            manualBonusList[bonusType].push(bonusInfo);
                    }
                    this.loginBonuses = manualBonusList['login'];
                    this.manualBonuses = manualBonusList['manual'];
                }else{
                    this.loginBonuses = null;
                    this.manualBonuses = null;
                }
            }else{
                this.loginBonuses = null;
                this.manualBonuses = null;
            }
        }catch (e){
            this.loginBonuses = null;
            this.manualBonuses = null;
        }

    }

    _processActiveBonus(bonusList){
        var activeBonusList = [];
        let topPriority = 0;
        //console.log(bonusList.activeBonusList);
        if(bonusList.activeBonusList.Bonuses.BonusAwarded){
            if(bonusList.activeBonusList.Bonuses.BonusAwarded.length > 0){
                activeBonusList = bonusList.activeBonusList.Bonuses.BonusAwarded;
                let priority = [];
                for (const [key, bonusInfo] of Object.entries(activeBonusList)) {
                    activeBonusList[key]['displayData'] = []
                    activeBonusList[key]['displayData']['bonusAmount'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.BonusAmountGiven));
                    activeBonusList[key]['displayData']['maxBet'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.cappMaxBet));
                    activeBonusList[key]['displayData']['maxCashOut'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.MaxCashOut));
                    if(bonusInfo.BonusWagerRequirementAchieved){
                        activeBonusList[key]['displayData']['wagerAchieved'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.BonusWagerRequirementAchieved));
                        activeBonusList[key]['displayData']['wagerRemained'] = CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(bonusInfo.BonusWagerRequirementRemain));
                        activeBonusList[key]['displayData']['progressPercentage'] = (parseFloat(bonusInfo.BonusWagerRequirementAchieved)/parseFloat(bonusInfo.BonusWagerRequirement)) * 100;
                    }
                    if (bonusInfo.wagerRequirementMultiplier){
                        activeBonusList[key]['displayData']['wageringReq'] = parseInt(bonusInfo.wagerRequirementMultiplier)+'x';
                    }
                    //console.log(activeBonusList);
                    var expiryDate = ''
                    if(bonusInfo.ExpiryDateFixed){
                        expiryDate = bonusInfo.ExpiryDateFixed;
                    }else{
                        if(bonusInfo.ExpiryDate){
                            expiryDate = bonusInfo.ExpiryDate
                        }else{
                            expiryDate = ExpiryDaysFromAwarding
                        }
                    }

                    try{
                        activeBonusList[key]['displayData']['expiryDate'] = Helpers.formatDate(expiryDate);
                    }catch(e){
                        activeBonusList[key]['displayData']['expiryDate']="";
                    }

                    try{
                        activeBonusList[key]['displayData']['activationDate'] = Helpers.formatDate(bonusInfo.GivenDate);
                    }catch(e){
                        activeBonusList[key]['displayData']['activationDate']="";
                    }

                    if(activeBonusList.length == 1){
                        //if only have 1 active bonus make it as top active bonus remain active bonus list to null
                        this.topActiveBonus = activeBonusList[key];
                        activeBonusList = null;
                    }else{
                        this.topActiveBonus = null;
                        priority[key] = bonusInfo.Priority;
                    }
                }
                if(activeBonusList){
                    if(activeBonusList.length > 1){
                        //set top priority
                        [topPriority] = Object.entries(priority).sort(([ ,v1], [ ,v2]) => v1 - v2);
                        var topPriorityKey = topPriority[0]
                        this.topActiveBonus = activeBonusList[topPriorityKey];
                        delete activeBonusList[topPriorityKey];
                    }else{
                        this.topActiveBonus = null;
                    }
                }
                this.activeBonuses = activeBonusList;
            }else{
                this.topActiveBonus = null;
            }
        }
    }
    
    claimBonus(bonusAwardedPreID){
        return CAPP_API.claimBonus(bonusAwardedPreID, this.sessionKey)
        .then(data=>{
            console.log("claimBonus response",data);
            return data
        })
    }

    setTopPriorityBonus(bonusAwardedPreID){
        return CAPP_API.setTopPriorityBonus(bonusAwardedPreID, this.sessionKey)
        .then(data=>{
            console.log("setTopPriorityBonus response",data);
            return data
        })
    }

    redeemVoucherCode(voucherCode){
        return CAPP_API.redeemVoucherCode(voucherCode, this.sessionKey, this.data.LanguageISO)
        .then(data=>{
            console.log("redeemVoucherCode response",data);
            return data
        })
    }

    forfeitBonus(bonusAwardedID){
        return CAPP_API.forfeitBonus(bonusAwardedID, this.sessionKey)
        .then(data=>{
            console.log("forfeitBonus response",data);
            return data
        })
        
    }

    getAccountTransactions(startDate, endDate){
        return CAPP_API.getAccountTransactions(startDate, endDate, this.sessionKey)
        .then(data=>{
            console.log("getAccountTransactions response",data);
            data.Transactions.PaymentTransaction.forEach(item=>{
                item.AmountDisplay=CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(item.Amount));
                item.DateDisplay=Helpers.formatDate(item.Timestamp);
                item.TimeDisplay=Helpers.formatTime(item.Timestamp);
            })
            return data
        })
    }

    getPlayerGamePlayHistory(startDate, endDate, limit, page){
        return CAPP_API.getPlayerGamePlayHistory(startDate, endDate, limit, page, 1, this.sessionKey)
        .then(data=>{
            console.log("getPlayerGamePlayHistory response",data);
            return data
        })
    }

    getPlayerBonusHistory(dateFrom, dateTo, removeActives){
        return CAPP_API.getPlayerBonusHistory(dateFrom, dateTo, removeActives, this.sessionKey)
        .then(data=>{
            console.log("getPlayerBonusHistory response",data);
            return data
        })
    }

    getPlayerRacesHistory(dateFrom, dateTo, removeActives){
        return CAPP_API.getPlayerRacesHistory(dateFrom, dateTo, removeActives, this.sessionKey)
        .then(data=>{
            console.log("getPlayerRacesHistory response",data);
            return data
        })
    }

    getPlayerPointsHistory(dateFrom, dateTo){
        return CAPP_API.getPlayerPointsHistory(dateFrom, dateTo, this.sessionKey).then(data=>{
            return data.Sessions.SessionStat;
        })
    }

    getPlayerShopHistory(dateFrom, dateTo){

        return CAPP_API.getPlayerShopPurchasesHistory(dateFrom, dateTo, this.sessionKey).then(data=>{
            return data;
        })
    }
    
    activateAccount(activateCode, ea){
        return CAPP_API.activateAccount(activateCode, ea)
        .then(data=>{
            console.log("activateAccount response",data);
            return data;
        })
    }

    playerResetPasswordWithResetCode(emailAddress, passwordResetCode){
        return CAPP_API.playerResetPasswordWithResetCode(emailAddress, passwordResetCode)
            .then(data=>{
                console.log("playerResetPasswordWithResetCode response",data);
                return data;
            })
    }

    sendCountryRestrictionEmail(email,csrfToken){
        return CAPP_API.submitCountryRestrictionEmail(email,csrfToken);
    }

    requestOTPToken(mobilePrefix, mobilePhone){
        return CAPP_API.requestOTPPhoneValidationToken(this.sessionKey, mobilePrefix, mobilePhone)
        .then(data=>{
            console.log("requestOTPToken response",data);
            return data;
        })
    }

    submitOTPToken(identifier, token){
        return CAPP_API.sendOTPPhoneValidationToken(this.sessionKey, identifier, token)
        .then(data=>{
            console.log("submitOTPToken response",data);
            return data;
        })
    }

    requestOTPRequestUpdatePaymentAccountToken(paymentAccountId, paymentAccountReference){
        return CAPP_API.otpRequestUpdatePaymentAccountToken(this.sessionKey, paymentAccountId, paymentAccountReference)
            .then(data=>{
                console.log("requestOTPRequestUpdatePaymentAccountToken response",data);
                return data;
            })
    }

    submitOTPValidateUpdatePaymentAccountToken(identifier, token){
        return CAPP_API.otpValidateUpdatePaymentAccountToken(this.sessionKey, identifier, token)
            .then(data=>{
                console.log("submitOTPValidateUpdatePaymentAccountToken response",data);
                return data;
            })
    }

    _invalidateExistingDepositData(){
        Helpers.removeStorage('depositData_*', 'session');
        Helpers.removeStorage('withdrawalData_*', 'session');
    }

    _loadVipProgressData(){
        CAPP_API.getVipDetails(USER.sessionKey).then(data=>{
            console.log('_loadVipProgressData',data);
            try {
                this.data.CurrentProgressingLoyaltyPoints=data.CurrentProgressingLoyaltyPoints;
            }catch (e){}
        })

    }

    _loadPlayerAffData(){
        CAPP_API.getPlayerGetDetails(USER.sessionKey).then(data=>{
            console.log('_loadPlayerAffData',data);
            try {
                this.data.AffiliateCode=data.AffiliateCode;
                this.data.ExtAffiliateID=data.ExtAffiliateID;
            }catch (e){}
        })
    }

    triggerHotjarEventAttributes(){
        console.log("hotjar event user data",this.data);
        if(this.isLoggedIn){
            let player_id = this.data.ClientID;
            let countryCode = this.data.Attributes.PlayerAttribute.find(attr => attr.AttrName === 'Address2'); // get country code

            window.hj('identify',player_id,{
                email: this.data.EmailAddress,
                player_id: player_id,
                // affiliate_id: window.localStorage.getItem('affData').affID,
                affiliate_id: this.data.AffiliateCode,
                deposit_count: this.data.NumDeposits,
                total_deposit_amount: CURRENCY_HELPERS.getCurrencySymbolString(Helpers.formatNumber(this.data.DepositedAmount / 100)),
                vip_level: this.data.VIPLevel,
                current_loyalty_points: this.data.CurrentLoyaltyPoints,
                current_experience_points: this.data.CurrentProgressingLoyaltyPoints,
                registration_date: this.data.SignUpDate,
                total_real_money_balance: CURRENCY_HELPERS.getCurrencySymbolString(Helpers.formatNumber(this.data.CurrentRealBalance / 100)),
                CLTV: Helpers.formatNumber(this.data.CashflowLifetimeValueBase / 100),
                total_withdrawal_amount: CURRENCY_HELPERS.getCurrencySymbolString(Helpers.formatNumber(this.data.WithdrawnAmount / 100)),
                country_code: this.data.CountryISO,
                language: this.data.LanguageISO
            })
        }
    }

}



// expose USER as singleton
export const USER = new User();

USER.VERIFICATION_DOCUMENT_TYPE={
    ID:0,
    PROOF_OF_ADDRESS:1,
    ADDITIONAL:2,
    ACCOUNT_DOCUMENT:-1
};