import {EventEmitter} from 'events';
import {Helpers} from "../util/Helpers";


const BASE_URL=window.location.origin+"/api/";
const REF_DATA_URL=window.location.origin+"/reference_data/";
const BO_LANGUAGE=Helpers.getCookie('bo_language_code');
//const LANGUAGE=Helpers.getLanguageFromCookie();
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
class CAPPApi extends EventEmitter{

    getGameDetailLiteAbortController;
    searchGameMakersAbortController;

    constructor() {
        super();
        console.log("CAPP api instance created");
    }

    get(apiMethod, data, abortController = null){
        console.log("get ", BASE_URL+apiMethod);
        let skipNetworkError = false;

        if(data.hasOwnProperty("skipNetworkError")){
            skipNetworkError = data.skipNetworkError;
            delete data.skipNetworkError;
        }
        console.log(skipNetworkError, 'skipNetworkError');

        return fetch(`${BASE_URL}${apiMethod}?${data ? new URLSearchParams(data).toString() : ''}`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'application/json'
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            signal: abortController ? abortController.signal : null
        }).then(response=>{
            if(response.ok){
                return response.json();
            }else{
                throw new Error("Server error "+response.status);
            }

        }).then(data=>{
            let response=data.response;

            return(response);
        }).then(data=>this._checkForServerErrors(data))
        .catch(error => {
            /*
                In JS, an AbortError is thrown when an operation is stopped or canceled before it completes. For example, when using the JavaScript fetch() function, an AbortError is thrown if the abort() function is called on the associated AbortController before the fetch operation completes.
                The AbortError is thrown only if the operation is canceled while it is actively in progress. If the operation completed successfully or failed for another reason, an AbortError will not be thrown.
            */
            if (error.name === 'AbortError') {
                return;
            }

            if (skipNetworkError === true && error instanceof TypeError) {
                console.warn('Network error occurred: ', error);
                return;
            } else {
                console.error(error);
                this.emit("ServerError", error);
            }
        });
    }

    post(apiMethod, data){
        console.log("do post to", BASE_URL+apiMethod);

        return fetch(BASE_URL+apiMethod,{
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                //'Content-Type': 'application/json'
                 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: new URLSearchParams(data)//JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response=>{
            //console.log(response.headers.get("capp-clearance"));
            //window.CappClearance = response.headers.get("capp-clearance");
            return response.json();
        }).then(data=>{

            //todo check for server errors and do additional stuff like get error messages and so on
            let response=data.response;

            return(response);
        }).then(data=>this._checkForServerErrors(data))
          .catch(error=>{
            console.error(error);
            this.emit("ServerError",error);
        })
    }

    _checkForServerErrors(response){
        return new Promise((resolve,reject)=>{
            if(response.error && response.error.errorID){

                switch (response.error.errorID) {
                    case "500":
                    case 500:
                        if(Helpers.getCookie('is_logged_in')==1){
                            this.emit("SessionExpired",response.error);
                            resolve();
                        }else{
                            resolve(response);
                        }


                        break;
                    default:
                        resolve(response)
                }

            }else{
                resolve(response);
            }
        })
    }


    /**
     * @method login
     * @param username {string} - username
     * @param password {string} - password
     * @param language {string} - language code for preferred language
     * @param currentUrl {string} - current window url
     * @async
     * @description Login using REST api method sessionLogin
     */
    login(username, password){
        let data= {
            lang: BO_LANGUAGE,
            clientUsername: username,
            clientPassword: password,
            currentUrl: window.location.href
        };
        return this.post("sessionLoginV2",data);
    }

    /**
     * @method loginBySessionKey
     * @param sessionKey {string} - sessionKey
     * @param lang {string} - language code for preferred language
     * @param currentUrl {string} - current window url
     * @async
     * @description Login using REST api method sessionLogin
     */
    loginBySessionKey(sessionKey){
        let data= {
            sessionKey: sessionKey,
            lang: BO_LANGUAGE,
            currentUrl: window.location.href
        };
        return this.post("sessionLoginV2",data);
    }

    /**
     * @method loginAsPlayer
     * @param username {string} - username
     * @param password {string} - password
     * @param verificationKey {string} - verificationKey
     * @param language {string} - language code for preferred language
     * @param currentUrl {string} - current window url
     * @async
     * @description Login using REST api method sessionLogin
     */
    loginAsPlayer(username, password, verificationKey){
        let data= {
            lang: BO_LANGUAGE,
            userUsername: username,
            userPassword: password,
            playerVerificationKey: verificationKey,
            currentUrl: window.location.href
        };
        return this.get("playerlogintroubleshoot",data);
    }

    /**
     * @method logout
     * @param sessionKey {string} - user session key
     * @description Logout using REST api method sessionLogout
     */
    logout(sessionKey){
       let skipNetworkError = false;

       if(window.reloadOnLogout){
           skipNetworkError = true;
       }

       let data={
           sessionKey: sessionKey,
           skipNetworkError: skipNetworkError
       }
       return this.get("sessionLogout",data);
    }

    loadApplicationStrings(){
        return fetch(`${REF_DATA_URL}alert_strings/${LANGUAGE}.json`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }).then(response=>{
            return response.json();
        });
    }

    loadWPMLStrings(){
        return fetch(`${REF_DATA_URL}wpml_strings/${LANGUAGE}.json?v=${window.wpmlVersion}`,{
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors', // no-cors, *cors, same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }).then(response=>{
            return response.json();
        });
    }

    loadUser(sessionKey){
        let data={
            sessionKey: sessionKey,
            lang: LANGUAGE
        }
        return this.get("playerGetAllDetailsForLoggedIn",data);
    }

    getPlayerBalance(sessionKey,lang){
        let data = {
            lang: lang,
            sessionKey: sessionKey
        };
        return this.get('playerGetQuickBalance',data);
    }

    getAccessStatus(sessionKey){
        return this.get('playerGetAccessStatus',{sessionKey:sessionKey});
    }

    getPaymentAccounts(sessionKey,lang){
        let data = {
            lang: lang,
            sessionKey: sessionKey
        };
        return this.get('playerGetPaymentAccounts',data);
    }

    resendActivationCode(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('playerResendActivationCode',data);
    }

    resetPassword(email){
        let data = {
            emailAddress: email,
            // userName: username
        }
        return this.get("playerRequestPasswordReset",data);
    }

    changePassword(oldPassword, newPassword, sessionKey){
        let data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            sessionKey: sessionKey
        }
        return this.post('playerUpdateAccountPassword', data);
    }

    updatePlayerInfo(data){
        return this.post('playerUpdateAccount', data);
    }

    register(data){

        if(window.localStorage.getItem('affData')){
            try{
                const affData = JSON.parse(window.localStorage.getItem('affData'));

                if((typeof affData) === 'object' && affData !== null){
                    if(affData.affToken){
                        data.affToken = affData.affToken;
                    }

                    if(affData.affID){
                        data.affID = affData.affID;
                    }

                    if(affData.affDetails){
                        data.affDetails = affData.affDetails;
                    }

                    if(affData.referer){
                        data.referer = affData.referer;
                    }

                    if(affData.refererURL){
                        data.refererURL = affData.refererURL;
                    }

                    if(affData.queryParams){
                        data.queryParams = affData.queryParams;
                    }
                }
            }catch (e){
                console.log(e);
            }
        }

        if(window.isSimplifiedSignUpForm){
            return this.post('playerRegisterAccountSimplify', data);
        }
        else{
            return this.post('playerRegisterAccount', data);
        }
    }

    registerConfirmLocation(clientId,signature,answer){
        let data={
            clientId:clientId,
            signature:signature,
            answer:answer
        }
        return this.post('playerConfirmLocation', data);
    }

    checkPlayerInvite(email){
        let data={
            email:email
        }
        return this.post('playerIsPlayerInvited', data);
    }



    /*BANK FUNCTIONS START*/
    getAllDepositMethods(sessionKey, countryISO, currencyISO, lang){
        let data={
            sessionKey:sessionKey,
            countryISO:countryISO,
            currencyISO:currencyISO,
            lang:lang
        };
        return this.get("transactionGetAllDepositMethods",data);
    };

    getAllPaymentAccounts(sessionKey,lang){
        let data={sessionKey:sessionKey, lang:lang};
        return this.get("playerGetPaymentAccounts",data);
    }

    /*NOTE: Username is different than the player's email address for those player accounts registered before year 2020*/
    getPaymentExternalLink(sessionKey, paymentMethod, processType, amount, bonusRuleID,bonusCode, username, first_name, last_name, currency_code, country_code, languageISO, additionalData){
        let data = {
            sessionKey: sessionKey,
            amount: amount,
            paymentMethod: paymentMethod,
			processType: processType,
            bonusRuleID: bonusRuleID,
            bonusCode: bonusCode,
            merchant_customer_id: username,
            country_code: country_code,
            currency_code: currency_code,
            languageISO: languageISO,
            first_name: first_name,
            last_name: last_name
        };

        if(additionalData){
            for(let prop in additionalData){
                data[prop]=additionalData[prop];
            }
        }

        return this.post('getPaymentExternalLink', data);
    }

    submitPaymentData(sessionKey, depositAmount, paymentMethod, bonusRuleID,bonusCode, siteType, phoneNumber){
        let data = {
            sessionKey: sessionKey,
            depositAmount: depositAmount,
            paymentMethod:paymentMethod,
            bonusRuleID: bonusRuleID,
            bonusCode: bonusCode,
            siteType: siteType
        };
        if(phoneNumber){
            data.accountReference=phoneNumber;
        }
        return this.get('transactionDepositAmount', data);
    }

    submitFlexepinPaymentData(sessionKey, pin, depositAmount, bonusRuleID, bonusCode){
        let data = {
            sessionKey: sessionKey,
            pin:pin,
            depositAmount: depositAmount,
            bonusRuleID: bonusRuleID,
            bonusCode: bonusCode,
            paymentMethod:"Flexepin_Voucher"
        };
        return this.get('extFlexepinPayment',data);
    }

    launchPIQ(sessionKey,depositAmount, bonusRuleID, bonusCode, type,platformPaymentMethod){
        let data = {
            sessionKey: sessionKey,
            amount: depositAmount,
            bonusId: bonusRuleID,
            bonusCode: bonusCode,
            method: type, //deposit or withdraw
            platformPaymentMethod:platformPaymentMethod 
        };
        return this.post('launchPIQ', data);
    }

    getPIQDepositBonuses(sessionKey,lang){
        let data={
            sessionKey:sessionKey,
            lang:lang
        }
        return this.get('playerGetDepositBonuses',data);
    }


    getBonusUsingBonusCode(sessionKey,depositAmount,paymentMethod,bonusRuleID,bonusCode){
        let data = {
            sessionKey: sessionKey,
            depositAmount: depositAmount,
            paymentMethod: paymentMethod,
            bonusRuleID: bonusRuleID,
            bonusCode:bonusCode
        };
        return this.get('getBonusUsingBonusCode', data);
    }

    getAllWithdrawalmethods(sessionKey, countryISO, currencyISO, lang){
        let data = {
            sessionKey: sessionKey,
            countryISO: countryISO,
            currencyISO: currencyISO,
            lang: lang
        };
        return this.get('transactionGetAllWithdrawOptions',data);
    }

    getExistingBankAccounts(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('viewWithdrawalAccount',data);
    }

    bankTransferWithdrawal(sessionKey,amount,data){

        let formData=new FormData();
        formData.append('sessionKey',sessionKey);
        formData.append('amount',amount);
        for(let key in data){
            formData.append(key,data[key]);
        }
        console.log('form data',formData);


        return new Promise(res=> {

            var xhr = new XMLHttpRequest();
            xhr.open('POST', BASE_URL + "withdrawBankTransfer", true);
            xhr.setRequestHeader('x-requested-with','XMLHttpRequest');


            xhr.onload = function () {

                if (xhr.status === 200) {
                    let o=JSON.parse(xhr.response);

                    res(o.response);
                } else {
                    res({
                        error:{
                            text: "Server error"
                        }
                    })
                }
            }
            xhr.send(formData);
        });
    }

    ibV4Withdrawal(sessionKey,username,amount,bankCountry,bankAccountHolderName,bankName,bankSwiftCode,bankBSBCode,bankAccountNumber,currencyCode,countryISO,lang){
        let data = {
            sessionKey:sessionKey,
            username:username,
            amount:amount,
            bankCountry:bankCountry,
            bankAccountHolderName:bankAccountHolderName,
            bankName:bankName,
            bankSwiftCode:bankSwiftCode,
            bankBSBCode:bankBSBCode,
            bankAccountNumber:bankAccountNumber,
            currencyCode:currencyCode,
            countryISO:countryISO,
            lang:lang
        };
        return this.post('ibV4MakeWithdrawalReserveFunds',data);
    }

    cryptopayWithdrawal(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.get('cryptopayWithdrawal',data);
    }

    submitWithdrawData(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.get('transactionWithdrawAmount',data);
    }

    pseCreateWithdrawal(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;

        return this.post('pseCreateWithdrawal',data);
    }

    getPendingWthdrawals(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('getPendingWithdrawalsForPlayer',data);
    }

    cancelPendingWithdrawal(sessionKey, transactionKey){
        let data = {
            sessionKey: sessionKey,
            transactionKey: transactionKey,
            reason:""
        };
        return this.get("transactionCancelWithdrawal",data);
    }


    removeExistingWithdrawAccount(sessionKey, accountName){
        let data={
            sessionKey: sessionKey,
            uniqueName:accountName
        };
        return this.get("removeWithdrawalAccount",data);
    }


    /*BANK FUNCTIONS END*/




    
    checkEmailExist(emailAddress){
        let data = {
            emailAddress : emailAddress
        }
        return this.get('playerValidateEmailExists', data);
    }

    checkSignUpCode(SignupCode){
        let data = {
            SignupCode : SignupCode
        }
        return this.get('getSignupCode', data);
    }

    getCountryCodeInfo(isSignup){
        let data = {};

        if((typeof isSignup) !== 'undefined'){
            data = {
                isSignup : isSignup
            }
        }
        return this.get('getCountryCodeInfo', data);
    }

    getSocialMediaList(){
        let data = {
            language: LANGUAGE,
        }
        return this.get('getSocialMediaList', data);
    }


    getCitizenshipList(){
        return this.get('getCitizenshipList', {});
    }

    getSecretQuestionList(){
        let data = {
            lang : LANGUAGE
        }
        return this.get('playerGetSecretQuestionsByLanguageCode', data);
    }

    transactionGetAllDepositLimit(sessionKey){
        let data = {
            sessionKey:sessionKey
        }
        return this.get('transactionGetAllDepositLimit', data);
    }

    gameLimitGetAll(sessionKey){
        let data = {
            sessionKey:sessionKey
        }
        return this.get('gameLimitGetAll', data);
    }

    transactionSetDepositLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('transactionSetDepositLimit', data);
    }

    transactionRemoveDepositLimit(interval, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval
        }
        return this.get('transactionRemoveDepositLimit', data);
    }

    setBetLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('gameLimitSetBetAmount', data);
    }

    removeBetLimit(interval, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval
        }
        return this.get('gameLimitRemoveBetAmount', data);
    }

    setLossLimit(interval, amount, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval,
            amount: amount,
        }
        return this.get('gameLimitSetLossAmount', data);
    }

    removeLossLimit(interval, sessionKey){
        let data = {
            sessionKey: sessionKey,
            interval: interval
        }
        return this.get('gameLimitRemoveLossAmount', data);
    }

    setTimeLimit(timeDuration, sessionKey){
        let data = {
            sessionKey: sessionKey,
            timeDuration: timeDuration,
        }
        return this.get('gameLimitSetTimeLimit', data);
    }

    setSelfExclude(minutes, isIndefinite, reason, sessionKey){
        let data = {
            sessionKey: sessionKey,
            numMinutes: minutes,
            isIndefinite: isIndefinite,
            reason: reason,
        }
        return this.get('playerRestrictionSetSelfExlusion', data);
    }

    getAvailableBonuses(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('playerGetDepositAndPreAuthBonuses', data);
    }

    getActiveBonuses(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('playerGetActiveBonuses', data);
    }

    claimBonus(bonusAwardedPreID, sessionKey){
        let data = {
            bonusAwardedPreID: bonusAwardedPreID,
            sessionKey: sessionKey
        }
        return this.get('playerAuthBonus', data);
    }
    
    setTopPriorityBonus(bonusAwardedPreID, sessionKey){
        let data = {
            bonusAwardedID: bonusAwardedPreID,
            sessionKey: sessionKey
        }
        return this.get('playerSetTopPriorityForBonusAwareded', data);
    }

    claimBonusByBonusId(sessionKey, bonusId){
        let data = {
            sessionKey:sessionKey,
            bonusRuleID:bonusId
        };
        return this.get('playerClaimBonusByBonusId', data);
    }

    redeemVoucherCode(voucherCode, sessionKey, lang){
        let data = {
            voucherCode: voucherCode,
            sessionKey: sessionKey,
            lang: lang
        }
        return this.get('voucherRedeemCode', data);
    }

    forfeitBonus(bonusAwardedID, sessionKey){
        let data = {
            bonusAwardedID: bonusAwardedID,
            sessionKey: sessionKey
        }
        return this.get('playerForfeitBonus', data);
    }

    getPopularSearchTerms(){
        return this.get('getPopularSearchTerms',{});
    }

    getGameDetailLite(searchString,gameCategory, gameMakers, gameThemes, pokieFilters, lineFilters, featureFilters, newParam, hot, order, orderBy, limit, page, lang, platform){
        let data = {
            s: searchString,
            gameCategory: gameCategory,
            gameMakers: gameMakers,
            gameThemes: gameThemes,
            pokieFilters: pokieFilters,
            lineFilters: lineFilters,
            featureFilters: featureFilters,
            new: newParam,
            hot: hot,
            order: order,
            orderby: orderBy,
            limit: limit,
            page: page,
            lang: lang,
            platform: platform
        }

        this.getGameDetailLiteAbortController = new AbortController();
        return this.get('gamesGetDetailsLite', data, this.getGameDetailLiteAbortController);
    }

    getGamesByGameIds(gameIds, lang, limit = ""){
        let data = {
            lang: lang,
            page:0,
            limit: limit,
            order: "MostPopular",
            gameids:gameIds.join()
        }
        return this.get('gamesGetDetailsLite', data);
    }

    getRecommendedGames(sessionKey){
        let data={
            sessionKey: sessionKey,
            lang: LANGUAGE
        }
        return this.get("getRecommendedGames",data);
    }

    loadLastPlayedGames(sessionKey,limit){
        let data = {
            sessionKey: sessionKey,
            limit:limit
        };
        return this.get('getLastPlayedGames',data);
    }

    loadGamesPage(category,pageSize,pageNum,language){
        let data = {
            gameCategory: category,
            order: "MostPopular",
            limit: pageSize,
            page: pageNum,
            lang: language
        }
        return this.get('gamesGetDetailsLite', data);
    }

    getAccountTransactions(filterStartDate, filterEndDate, sessionKey){
        let data = {
            filterStartDate: filterStartDate,
            filterEndDate: filterEndDate,
            sessionKey: sessionKey
        }
        return this.get('transactionGetAccountTransactions', data);
        // sessionKey: 6489a859deba4a669c408bbbb8b62b46
        // filterStartDate: 2020-05-05
        // filterEndDate: 2020-05-06

    }

    getPlayerGamePlayHistory(startDate, endDate, limit, page, filter, sessionKey){
        let data = {
            dateFrom: startDate,
            dateTo: endDate,
            perPage: limit,
            pageNo: page,
            filter: filter,
            sessionKey: sessionKey
        }
        return this.get('playerGetPlayerTrailWithFilter', data);
    }

    getPlayerBonusHistory(dateFrom, dateTo, removeActives, sessionKey){
        let data = {
            dateFrom : dateFrom,
            dateTo : dateTo,
            removeActives: removeActives,
            sessionKey: sessionKey
        }
        return this.get('playerGetAllBonusesDetails', data);
    }

    getPlayerRacesHistory(dateFrom, dateTo, removeActives, sessionKey){
        let data = {
            dateFrom : dateFrom,
            dateTo : dateTo,
            removeActives: removeActives,
            sessionKey: sessionKey
        }
        return this.get('playerGetAllTournamentStatuses', data);
    }

    getGameUrl(sessionKey, gameId, fun, lang,sid){
        
        sid =sid?sid:'';

        let data={
            sessionKey:sessionKey,
            gameID:gameId,
            fun:fun,
            lang:lang,
            sid:sid
        };

        return this.get('gameGetStartURL', data);
    }

    gameStartPreCheck(sessionKey, gameId){
        let data={
            sessionKey:sessionKey,
            gameId:gameId,
        };
        return this.get('gameStartPreCheck', data);
    }

    getGameInfo(gameId,lang){
        let data={
            gameID:gameId,
            lang:lang
        }
        return this.get('gameGetGameInfo', data);
    }

    getFavouriteGames(sessionKey){
        let data={
            sessionKey:sessionKey
        };
        return this.get('gameGetPlayerFavouriteGames', data);
    }

    setFavouriteGameFlag(sessionKey, gameId, value){
        let data = {
            sessionKey: sessionKey,
            gameID: gameId,
            assignFlag: value
        };
        return this.get('gameMarkGameAsFavourite', data);
    }


    getPlayerPointsHistory(dateFrom, dateTo, sessionKey){
        let data = {
            dateFrom : dateFrom,
            dateTo : dateTo,
            sessionKey: sessionKey
        }
        return this.get('sessionGetSessionStatisticsHistory', data);
    }

    getPlayerShopPurchasesHistory(dateFrom, dateTo, sessionKey){
        let data = {
            dateFrom : dateFrom,
            dateTo: dateTo,
            sessionKey: sessionKey
        }
        return this.get('playerGetLoyaltyAdjustments', data);
    }

    loadShopItems(sessionKey){
        let data={
            sessionKey:sessionKey
        }
        return this.get('loyaltyGetRedemptionSchemesForPlayer',data);
    }

    buyShopItem(prizeId,sessionKey){
        let data = {
            loyaltyRedemptionID: prizeId,
            sessionKey: sessionKey
        }
        return this.get('loyaltyRedeemLoyaltyPointsToPrize', data);
    }

    buyDepositShopItem(prizeId,sessionKey){
        let data = {
            loyaltyRedemptionID: prizeId,
            sessionKey: sessionKey
        }
        return this.get('loyaltyRedeemLoyaltyPointsToDepositPrize', data);
    }




    activateAccount(activateCode, ea){
        let data = {
            ea: ea,
            activateCode: activateCode
        }
        return this.get('playerActivateAccount', data)
    }

    playerResetPasswordWithResetCode(emailAddress, passwordResetCode){
        let data = {
            emailAddress: emailAddress,
            passwordResetCode: passwordResetCode
        }
        return this.get('playerResetPassword', data)
    }

    uploadDocument(formData){

        return new Promise(res=> {

            var xhr = new XMLHttpRequest();
            xhr.open('POST', BASE_URL + "kycUploader", true);
            xhr.setRequestHeader('x-requested-with','XMLHttpRequest');

            xhr.onload = function () {

                if (xhr.status === 200) {
                    let o=JSON.parse(xhr.response);
                    res(o.response);
                } else {
                    res({
                        error:{
                            text: "Server error"
                        }
                    })
                }
            }
            xhr.send(formData);
        });

    }

    getNewFeeds(){
        let data = {
            lang: LANGUAGE
        }
        return this.get('getFeeds', data)
    }

    getPageSettings(lang,sessionKey){
        let data = {
            lang: lang,
            sessionKey:sessionKey
        }

        return this.get("getPageSettings", data);
    }

    submitCountryRestrictionEmail(email, csrfToken){
        let data = {
            email: email,
            csrf_token: csrfToken
        };
        return this.post(`addRestrictedCountryUserSubmission`,data);
    }

    getRegistrationBanner(lang){
        let data={
            language:lang
        };
        return this.get('getRegistrationBanner',data);
    }

    getRaceLeaderboard(tournamentId, pageNum, numItems, currencyCode){
        let data={
            tournamentID: tournamentId,
            pageNum: pageNum,
            amountPerPage:numItems,
            pageCurrencyCode:currencyCode
        };
        return this.get('tournamentGetLeaderBoard',data);
    }

    getTournamentsAndLeaderboard(pageNum, numItems, tournamentStatus, currencyCode){
        let data={
            pageNum: pageNum,
            amountPerPage: numItems
        };

        if(tournamentStatus){
            data.tournamentStatus = tournamentStatus; // Default: CURRENT(C)
        }

        if(currencyCode){
            data.pageCurrencyCode = currencyCode;
        }
        return this.get('tournamentGetTournamentsAndLeadearboard',data);
    }

    tournamentOptIn(sessionKey, tournamentId){
       let data = {
           sessionKey: sessionKey,
           tournamentID: tournamentId
       };
        return this.get('tournamentOptInTournament',data);
    }

    tournamentOptOut(sessionKey, tournamentId){
       let data = {
           sessionKey: sessionKey,
           tournamentID: tournamentId
       };
        return this.get('tournamentOptOutOfTournament',data);
    }

    loadJackpotFeed(gameIds, currency){
        let data={
            gameIds:gameIds,
            currency:currency
        }
        return this.post('getJackpotFeeds',data);
    }

    resendActivationCode(sessionKey){
        let data = {
            sessionKey: sessionKey
        };
        return this.get('playerResendActivationCode',data);
    }

    getClientJWTToken(){
        return this.post('getClientJWTToken',{});
    }

    getWinnersFeed(numOfRecords,winThreshold,minMultiplier){
        let data={
            numOfRecords:numOfRecords,
            winThreshold:winThreshold,
            minMultiplier: minMultiplier
        }
        return this.get('getWinnersFeed',data);

    }

    requestOTPPhoneValidationToken(sessionKey, mobilePrefix, mobilePhone){
        let data = {
            sessionKey: sessionKey,
            mobilePrefix: mobilePrefix,
            mobilePhone: mobilePhone
        }
        return this.get('playerOtpRequestPhoneValidationToken',data);
    }

    sendOTPPhoneValidationToken(sessionKey, identifier, code){
        let data = {
            sessionKey: sessionKey,
            identifier: identifier,
            code: code
        }
        return this.get('playerOtpValidatePhoneValidationToken', data);
    }

    bonusQueueAddPlayer(sessionKey, bonusId, remarks = ""){
        let data = {
            sessionKey:sessionKey,
            inputJSON: btoa(JSON.stringify({
                Data: [{
                    BonusId:bonusId,
                    Remarks:remarks
                }]
            })),
        };
        return this.get('bonusQueueAddPlayer', data);
    }

    searchGameMakers(searchString) {
        const data = {
            s: searchString,
        };

        this.searchGameMakersAbortController = new AbortController();
        return this.get('searchGameMakers', data, this.searchGameMakersAbortController);
    }

    otpRequestUpdatePaymentAccountToken(sessionKey, paymentAccountId, paymentAccountReference){
        let data = {
            sessionKey: sessionKey,
            paymentAccount: JSON.stringify({
                PaymentAccountId: paymentAccountId,
                PaymentAccountReference: paymentAccountReference
            })
        }
        return this.get('playerOtpRequestUpdatePaymentAccountToken',data);
    }

    otpValidateUpdatePaymentAccountToken(sessionKey, identifier, code){
        let data = {
            sessionKey: sessionKey,
            identifier: identifier,
            code: code
        }
        return this.get('playerOtpValidateUpdatePaymentAccountToken', data);
    }

    getVipDetails(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('playerGetVipDetails', data);
    }

    getLoyaltyPointsLevel(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('loyaltyPointsLevel', data);
    }

    getPlayerGetDetails(sessionKey){
        let data = {
            sessionKey: sessionKey
        }
        return this.get('PlayerGetDetails', data);
    }

    cryptopayWithdrawalReserveFunds(sessionKey,amount,data){
        data.sessionKey= sessionKey;
        data.amount= amount;
        return this.get('cryptopayWithdrawalReserveFunds',data);
    }

    getOtpRequestTokenUsingMobilePhone(sessionKey, type){
        let data = {
            sessionKey: sessionKey,
            type: type
        }
        return this.get('playerOtpRequestTokenUsingMobilePhone', data);
    }

    otpValidateToken(sessionKey, identifier, otp_code){
        let data = {
            sessionKey: sessionKey,
            identifier: identifier,
            code: otp_code
        }
        return this.get('playerOtpValidateToken', data);
    }
}

// expose CAPPApi as singleton
export const CAPP_API = new CAPPApi();