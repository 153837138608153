import {AbstractRouter} from "../../../../core/capp-V3.5-js-lib/src/js/core/AbstractRouter";

export class CAPPRouter extends AbstractRouter{
    constructor() {
        super();
    }

    _defineRoutes() {
        console.log('DEFINING ROUTES');
        this.router.on(
            {
                'login': ()=> {
                    console.log('handle login route');
                    this.routeData={action: 'login'};
                },
                'signup': ()=> {
                    console.log('handle signup route');
                    this.routeData={action: 'signup'};
                },
                'reset-password': ()=> {
                    console.log('handle reset password route');
                    this.routeData={action: 'resetPassword'};
                },
                "deposit/bonus/:id": (params) => {
                    this.routeData = { action: 'deposit', bonusId: params.id };
                },
                "profile":()=>{
                    this.routeData={action: 'profile'};
                },
                "personal-details":()=>{
                    this.routeData={action: 'personal-details'};
                },
                "verification":()=>{
                    this.routeData={action: 'verification'};
                },
                "verification/email":()=>{
                    this.routeData={action: 'verification', view:'email'};
                },
                "deposit":()=>{
                    this.routeData={action: 'deposit'};
                },
                "withdraw":()=>{
                    this.routeData={action: 'withdraw'};
                },
                "history":()=>{
                    this.routeData={action: 'history'};
                },
                "limits":()=>{
                    this.routeData={action: 'limits'};
                },
                "bonuses":()=>{
                    this.routeData={action: 'bonuses'};
                },
                "inbox":()=>{
                    this.routeData={action: 'inbox'};
                },
                "claim/:bonusId": (params) => {
                    this.routeData = { action: 'claim', bonusId: params.bonusId };
                },
                "points":() => {
                    this.routeData = { action: 'points'};
                },
                /*
                ":gameMakers": (param) => {
                    this.routeData = {action: param.gameMakers, isGameMaker: true};
                }, */

                /*
                ':action':(params)=>{
                    console.log("Action",params)
                    this.routeData={action:params.action}
                },*/

                '/': ()=> {
                    console.log("Open Default");
                    this.setRoute("");
                    this.routeData=null;
                }
            }
        ).resolve();
    }
}

export const CAPP_ROUTER=new CAPPRouter();