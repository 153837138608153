
class SubViewManagerClass{
    constructor() {
        this._currentSubView=null;
    }


    add(subView){
        if(this._currentSubView==subView){return;}
        if(this._currentSubView){
            this._currentSubView.close();
        }
        this._currentSubView=subView;

    }

    removeSubview(subView){
        if(this._currentSubView==subView){
            this._currentSubView.close();
            this._currentSubView=null;
        }

    }

    closeCurrentSubview(){
        if(this._currentSubView!=null){
            this._currentSubView.close();
            this._currentSubView=null;
        }

    }


}

export const SubViewManager = new SubViewManagerClass();