import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {LitElement,html} from "lit-element";
import {unsafeHTML} from "lit-html/directives/unsafe-html";


export class CAPPPopup extends PopupBase{
    constructor(title,message,buttons,callback) {
        super(POPUP_MOBILE_ALIGN.BOTTOM,POPUP_DESKTOP_ALIGN.CENTER);
        this._title=title;
        this._message=message;
        this._buttons=buttons;
        this._callback=callback;
        this.useManualClose=false;
    }

    set mobileAlignment(value){
       this._mobileAlign=value;
    }

    get mobileAlignment(){
        return this._mobileAlign;
    }

    set desktopAlignment(value){
       this._desktopAlign=value;
    }

    get desktopAlignment(){
        return this._desktopAlign;
    }


    _renderContent(){
        return html`
            <div class="popup-wrap">
                <img class="icon" alt="Info Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}">
                <h4>${this._title}</h4>
                <div class="popup-body">${unsafeHTML(this._message)}</div>
                <div class="popup-buttons">
                    ${this._buttons.map((button,index)=>html`
                        <button class="${button.type}" .btnIndex=${index} @click=${this._onButtonClick}>${button.label}</button>
                    `)}
                </div>
            </div>
        `;
    }

    _onButtonClick(e){
        if(!this.useManualClose){
            this._close();
        }

        if(this._callback){
            this._callback(e.target.btnIndex);
        }
    }
}

customElements.define('capp-popup', CAPPPopup);


//TODO remove the test code
window.testPopup1=function(){
    new CAPPPopup(
        'Test Popup',
        "Sorry something is wrong and isn’t working properly. Please contact customer service.",
        [{label:"OK",type:'btn-primary'},{label:"Cancel",type:'btn-secondary'}]
    )

};

window.testPopup2=function(){
    let p=new CAPPPopup(
        'Test Popup',
        "Sorry something is wrong and isn’t working properly. Please contact customer service.",
        [{label:"OK",type:'btn-primary'},{label:"Cancel",type:'btn-secondary'}]
    )

    p.desktopAlignment=POPUP_DESKTOP_ALIGN.RIGHT;
    p.mobileAlignment=POPUP_MOBILE_ALIGN.TOP;
};
window.testPopup3=function(){

    let p=new CAPPPopup(
        'Test Popup3',
        "Sorry something is wrong and isn’t working properly. Please contact customer service.",
        [{label:"My first button",type:'btn-primary'},{label:"My second button",type:'btn-secondary'}],
    )
    p.desktopAlignment=POPUP_DESKTOP_ALIGN.LEFT;
    p.mobileAlignment=POPUP_MOBILE_ALIGN.TOP;

};



