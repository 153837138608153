import {html} from "lit-element";
import {FormElementBase} from "./FormElementBase";

export class CAPPInput extends FormElementBase{
    static INPUT_TYPE = {
        TEXT:'text',
        PASSWORD:'password',
        NUMBER:'number'
    }

    static get properties() {
        return {
            value: {type:String},
            placeholder: {type:String},
            type:{type:String},
            disabled:{type:Boolean}
        };
    }

    constructor() {
        super();
        this._inputEl=null;
        this._valid=null;
        this._value=this.value?this.value:"";
        this._showPassword=false;
    }

    set value(val){
        this._value=val;
        if(this._inputEl){
            this._inputEl.value=val;
        }
        this.requestUpdate();
    }

    get value(){
        let val;
        if(this.type==CAPPInput.INPUT_TYPE.NUMBER){
            val=parseFloat(this._value)
        }else{
            val=this._value;
        }
        return val;
    }

    set valid(value){
        this._valid=value;
        this.requestUpdate();
    }

    get valid(){
        return this._valid;
    }

    firstUpdated(changedProperties) {
        this._inputEl=this.renderRoot.querySelector('input');
        this._inputEl.addEventListener('change',e=>{
            e.stopPropagation();
            e.preventDefault();
            this._value=this._inputEl.value;
            this._dispatchChangeEvent();
        })
        this._inputEl.addEventListener('keyup',e=>{
            e.stopPropagation();
            e.preventDefault();
            this._value=this._inputEl.value;
            this._dispatchKeyupEvent(e);
        })

        this._inputEl.addEventListener('focus',e=>{
            e.stopPropagation();
            e.preventDefault();
            const event = new CustomEvent('focus', {});
            this.dispatchEvent(event);
        })
        this._inputEl.addEventListener('blur',e=>{
            e.stopPropagation();
            e.preventDefault();
            const event = new CustomEvent('blur', {});
            this.dispatchEvent(event);
        })



    }



    _renderInput() {
        let currentType=this.type;
        if(this.type==CAPPInput.INPUT_TYPE.PASSWORD && this._showPassword){
            currentType=CAPPInput.INPUT_TYPE.TEXT;
        }

        let icons=this._getIcons();
        console.log(icons);

        return html`
            <div class="input-wrap">
                <input
                    placeholder="${this.placeholder?this.placeholder:''}" 
                    spellcheck="false" 
                    .value="${this._value}"
                    type="${currentType}"
                    autocapitalize="none"
                    inputmode="${currentType==CAPPInput.INPUT_TYPE.NUMBER?'decimal':''}"
                    class="${icons.length>0?`pi-${icons.length}`:''}"
                    ?disabled=${this.disabled}
                    name="${this.name}"
                >
                <div class="icons">
                    ${icons}
                </div>
                
            </div>
        `;
    }

    _getIcons(){
        let icons=[];
        let icn=this._getValidityIcon();
        if(icn){icons.push(icn);}
        icn=this._getPasswordVisibilityIcon();
        if(icn){icons.push(icn);}
        return icons;
    }


    _getValidityIcon(){
        if(this._valid==null) return;
        if(this._valid){
            return html`<img class="icon success" alt="Valid Input Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}" >`
        }else{
            return html`<img class="icon warning" alt="Invalid Input Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}" >`
        }
    }

    _getPasswordVisibilityIcon(){
        if(this.type!=CAPPInput.INPUT_TYPE.PASSWORD){return;}
        let iconUrl;
        if(this._showPassword){
            iconUrl=`${CDN_ASSETS_URL}/icons/visible-eye.svg`;
        }else{
            iconUrl=`${CDN_ASSETS_URL}/icons/hidden-eye.svg`;
        }
        return html`
            <img class="icon password" alt="Password Icon" src="${iconUrl}" @click=${this._handleTogglePasswordClick}>
        `;
    }

    _handleTogglePasswordClick(e){
        this._showPassword=!this._showPassword;
        this.requestUpdate();
    }

    _dispatchChangeEvent(){
        let options = {
            detail: this.value
        };
        const event = new CustomEvent('change', options);
        this.dispatchEvent(event);
    }

    _dispatchKeyupEvent(e){
        let options = {
            detail: {
                value:this.value,
                keyCode:e.keyCode,
                originalEvent:e
            },

        };
        const event = new CustomEvent('keyup', options);
        this.dispatchEvent(event);
    }



}

customElements.define('capp-input', CAPPInput);