import {LitElement,html} from "lit-element";
import {SubViewManager} from "../../controllers/SubViewManager";
import {Helpers} from "../../util/Helpers";

export const SUB_VIEW_POSITION={
    LEFT:'left',
    RIGHT:'right',
    CENTER:'center'
}

export class SubViewBase extends LitElement{
    static CLOSE_ON_MASK_CLICK=true;

    constructor() {
        super();
        this._subViewPosition=SUB_VIEW_POSITION.RIGHT;
        this._deviceType=Helpers.getDeviceType();
        this._targetHolder=null;
    }

    set position(value){
        this._subViewPosition=value;
        this.requestUpdate();
    }

    get position(){
        return this._subViewPosition;
    }

    createRenderRoot() {
        return this;
    }

    open(){
        if(this._targetHolder){
            this._targetHolder.appendChild(this);
        }else{
            document.body.appendChild(this);
        }
        this.addToSubviewManager();
    }

    close(){
        if(this.parentElement){
            this.parentElement.removeChild(this);
        }
    }

    connectedCallback() {
        this.addToSubviewManager();
        super.connectedCallback();

    }

    addToSubviewManager(){
        SubViewManager.add(this);
    }

    disconnectedCallback() {
        SubViewManager.removeSubview(this);
        super.disconnectedCallback();
    }

    render(){
        return html`
            <div class="sub-view-wrap" @click=${this._handleMaskClick}>
                <div class="sub-view ${this.position} ${this._deviceType}">
                    ${this.renderViewContent()}
                </div>
            </div>
        `;
    }

    renderViewContent() {
        return html`<div>view content</div>`
    }

    _handleMaskClick(e){
        if(SubViewBase.CLOSE_ON_MASK_CLICK && e.target==e.currentTarget){
            this.close();
            location.href="#";
        }
    }


}