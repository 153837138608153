class PopupManager {
    constructor() {
        this.popupsQueue=[];
    }

    addPopup(popup){
        this.popupsQueue.push(popup);
        if(this.popupsQueue.length==1){
            setTimeout(()=>{
                this._showNextPopup();
            })

        }

    }

    _showNextPopup(){
        if(this.popupsQueue.length>0){
            this.popupsQueue[0]._show(()=>{
                this.popupsQueue.shift();
                this._showNextPopup();
            })
        }
    }
}

export const POPUP_MANAGER=new PopupManager();