import {USER} from '../core/User'

class CurrencyHelpers {
    static CURRENCY_MULTIPLIER=.01;
    static RIGHT_ALIGNED_CURRENCY_SYMBOL_PREFIX=' ';

    constructor() {
        this._isCrypto=false;
        this._isNonStableCrypto=false;
    }

    set isCrypto(val){
        this._isCrypto=val;
    }
    get isCrypto(){
        return this._isCrypto;
    }

    set isNonStableCrypto(val){
        this._isNonStableCrypto=val;
    }
    get isNonStableCrypto(){
        return this._isNonStableCrypto;
    }

    convertUserToSystemAmount(amount){
        return amount*(1/CurrencyHelpers.CURRENCY_MULTIPLIER);
    }

    convertSystemToUserAmount(amount){
        return amount*CurrencyHelpers.CURRENCY_MULTIPLIER;
    }

    formatCurrency(amount){
        if (typeof(amount) === 'undefined') {amount = 0;}

        let convertedAmount=this.convertSystemToUserAmount(parseFloat(amount));

        return this._applyFormatting(convertedAmount)
    }

    formatCurrencyWithoutConversion(amount){
        if (typeof(amount) === 'undefined') {amount = 0;}

        return this._applyFormatting(parseFloat(amount));
    }

    // To get currency ISO from the paymentMethod, e.g. return BTC from CryptoPay_BTC
    getCurrencyIsoByPaymentMethod(paymentMethod, isLowerCase) {
        var currencyISO = this.isCrypto ? USER.data.CurrencyISO : "BTC";

        if(paymentMethod != null){
            var paymentMethodChunks = paymentMethod.split('_');

            if(paymentMethodChunks.length > 1){
                currencyISO = paymentMethodChunks[paymentMethodChunks.length - 1];
            }
        }

        if(isLowerCase === true){
            currencyISO = currencyISO.toLowerCase();
        }
        return currencyISO;
    }

    _applyFormatting(amount){
        let formattedCurrencyString;
        if(this._isNonStableCrypto){
            //console.log('use crypto formatter',amount);
            formattedCurrencyString=this._formatCryptoCurrency(amount);
        }else{
            formattedCurrencyString=this._formatStandardCurrency(amount);
            //console.log('use standard formatter',amount);
        }
        return formattedCurrencyString;
    }


    _formatStandardCurrency(value){
        value=value.toFixed(2);
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }

    _formatCryptoCurrency(value){
        value=value.toFixed(8);
        return value;
    }

    _formatIntegerCurrency(value){
        value=parseInt(value);
        value=value?value:0;
        return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }

    getCurrencySymbolString(amountString){
        let currencySymbolPlacement=this.getCurrencySymbolPlacement();
        let currencySymbol;
        if(USER && USER.isLoggedIn){
            currencySymbol=USER.data.CurrencySymbol;
        }else {
            currencySymbol=window.defaultCurrencySymbol;
        }
        if(currencySymbolPlacement==="left") {
            return currencySymbol + amountString;
        }else{
            return amountString + CurrencyHelpers.RIGHT_ALIGNED_CURRENCY_SYMBOL_PREFIX + currencySymbol; //Currencies with a symbol placed on the right (like 100 € or 1,00 zł) typically require a space.
        }
    }

    getCurrencySymbolPlacement(){
        let currencySymbolPlacement;
        if(USER && USER.isLoggedIn) {
            currencySymbolPlacement = USER.data.CurrencyPosition === "right" ? "right" : "left";
        }else {
            currencySymbolPlacement = window.defaultCurrencyPosition === "right" ? "right" : "left";
        }
        return currencySymbolPlacement;
    }

    getRightAlignedCurrencySymbolPrefix(){
        return CurrencyHelpers.RIGHT_ALIGNED_CURRENCY_SYMBOL_PREFIX;
    }



}

export const CURRENCY_HELPERS=new CurrencyHelpers();



