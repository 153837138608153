import {html} from "lit-element";
import {FormElementBase} from "./FormElementBase";
import {Helpers} from "../../util/Helpers";

export class CAPPSelect extends FormElementBase{
    static INPUT_TYPE = {
        TEXT:'text',
        NUMBER:'number'
    }

    static MODE={
        DESKTOP:'desktop',
        MOBILE:'mobile'
    }

    static get properties() {
        return {
            placeholder: {type:String},
            type:{type:String},
            disabled:{type:Boolean},
            required:{type:Boolean}
        };
    }

    constructor() {
        super();
        this._inputEl=null;
        this._valid=null;
        this._listData=[];
        this._selectedItem=null;
        this.mode=Helpers.getDeviceType();
        this._selectMenuPosition=0;

    }

    get value(){
        return this._selectedItem ? this._selectedItem.value : null;
    }

    set value(val){
        if(val==null){
            this._selectedItem=null;
            this.requestUpdate();
            this._dispatchChangeEvent();
        }else{
            let item=this._listData.find(i=>i.value==val);
            this._selectItem(item);
            this._dispatchChangeEvent();
        }

    }

    set valid(value){
        this._valid=value;
        this.requestUpdate();
    }

    removeItem(itemValue){
        this._listData=this._listData.filter(item=>item.value!=itemValue);
    }

    connectedCallback() {
        let options=this.renderRoot.querySelectorAll('option');
        console.log('options',options);
        options.forEach(item=>{
            let opt={
                label:item.innerText,
                value:item.value,
            }
            if(item.hasAttribute('selected')){
                this._selectedItem=opt;
            }
            this._listData.push(opt);
        })

        super.connectedCallback();
    }

    _renderInput() {
        return html`
            <div class="input-wrap">
                ${this._selectedItem?html`
                    <div class="selected-value"><span title="${this._selectedItem.label}">${this._selectedItem.label}</span></div>
                `:html`
                    <span class="placeholder">${this.placeholder}</span>
                `}
                <div class="icons">
                    ${this._getValidityIcon()}
                    <img class="icon arrow" alt="Down Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/down-chevron.svg`}" >
                </div>
                <select name="${this.name}" @change=${this._handleSelectChange} @mousedown=${this._handleSelectClick} @keydown=${this._handleSelectKeyDown}>
                    ${this._listData.map(item=>html`
                        <option value="${item.value}" ?selected=${item==this._selectedItem} .item=${item}>${item.label}</option>
                    `)}
                </select>
            </div>
            ${this._desktopMenuOpened?this._renderSelectMenu():''}
        `;
    }

    _renderSelectMenu(){
        return html`
            <div class="select-menu" style="left:-1px;top:${this._selectMenuPosition}px;" @mouseleave=${this._closeDesktopSelectMenu}>
                <div class="placeholder-wrap" @click=${this._handleMenuCloseClick}>
                    <span class="placeholder">${this.placeholder}</span>
                    <div class="icons">
                        <img class="icon arrow" alt="Up Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/up-chevron.svg`}" >
                    </div>
                </div>
                <div class="items-wrap">
                    ${this._listData.map(item=>html`
                    <div class="menu-item ${item==this._selectedItem?'selected':''}" .item=${item} @click=${this._handleMenuClick}>${item.label}</div>
                    `)}
                </div>
            </div>            
        `
    }

    _getValidityIcon(){
        if(this._valid==null) return;
        if(this._valid){
            return html`<img class="icon success" alt="Valid Input Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}" >`
        }else{
            return html`<img class="icon warning" alt="Invalid Input Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}" >`
        }
    }

    _handleSelectClick(e){

        if(this.mode==CAPPSelect.MODE.DESKTOP){
            e.preventDefault();
            this._openDesktopSelectMenu();
        }
    }

    _handleSelectChange(e){
        e.stopPropagation();
        e.preventDefault();

        console.log('_handleSelectChange',e.target);
        this._selectedItem=this._findItemByValue(e.target.value);
        console.log(this._selectedItem);

        this._dispatchChangeEvent();
        this.requestUpdate().then(()=>{
            if(this._desktopMenuOpened){
                this._scrollToSelectedItem();
            }
        });

    }

    _findItemByValue(value){
        for (let i=0; i<this._listData.length; i++){
            if(this._listData[i].value==value){
                return this._listData[i];
            }
        }
        return null;
    }

    _handleMenuClick(e){
        this._selectItem(e.target.item);
        this._closeDesktopSelectMenu();
        this._dispatchChangeEvent();
    }

    _handleMenuCloseClick(){
        if(this.required!=true){
            this._selectItem(null);
        }

        this._closeDesktopSelectMenu();
        this._dispatchChangeEvent();
    }

    _selectItem(item){
        this._selectedItem=item;
        this.requestUpdate();
    }

    _openDesktopSelectMenu(){
        this._desktopMenuOpened=true;
        let inputEl=this.renderRoot.querySelector('.input-wrap');
        this._selectMenuPosition=inputEl.offsetTop-1;
        console.log('select menu opened');
        this.renderRoot.querySelector('select').focus();

        this.requestUpdate().then(()=>{
            this._scrollToSelectedItem();
        });
    }

    _closeDesktopSelectMenu(){
        this._desktopMenuOpened=false;
        console.log('select menu closed');
        this.requestUpdate();
    }

    _dispatchChangeEvent(){
        let options = {
            detail: this.value
        };
        const event = new CustomEvent('change', options);
        this.dispatchEvent(event);
    }



    _scrollToSelectedItem(){
        let selectMenu=this.renderRoot.querySelector('.select-menu');
        let selectedItem=selectMenu.querySelector('.menu-item.selected');

        console.log("selectedItem",selectedItem)
        if(selectedItem==null){
            return;
        }

        let itemsWrap=selectMenu.querySelector('.items-wrap');
        itemsWrap.scrollTop=selectedItem.offsetTop-itemsWrap.offsetHeight*.5;
    }

    _handleSelectKeyDown(e){

        if(e.key=='Enter'&&this.mode==CAPPSelect.MODE.DESKTOP){
            e.preventDefault();
            if(this._desktopMenuOpened){
                this._closeDesktopSelectMenu();
            }else{
                this._openDesktopSelectMenu();
            }
        }
    }




}

customElements.define('capp-select', CAPPSelect);