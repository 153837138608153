import { EventEmitter } from "events";
import Navigo from "navigo";

export class AbstractRouter extends EventEmitter {
  constructor() {
    super();
    this.routeData = null;
  }

  initialize() {
    this._initializeRouter();
    this._defineRoutes();
  }

  _initializeRouter() {
    let root = null;
    let useHash = true; // Defaults to: false
    //let hash = '#!'; // Defaults to: '#'
    this.router = new Navigo(root, useHash);

    this.router.hooks({
      //before: function(done, params) { ... },
      after: (params) => {
        console.log("After root resolve route-changed", params, this.routeData);
        this.emit("route-changed", this.routeData);
      },
      already: (params) => {
        console.log("After root resolve", params);
      },
    });
  }

  _defineRoutes() {
    throw new Error("Define routes in subclass");
  }

  navigateTo(path) {
    if (`#${path}` === window.location.hash) {
      window.location.reload();
    } else {
      this.router.navigate(path);
    }
  }

  setRoute(path) {
    //prevent setting # as route if initial clean page load (fix for ios browsers)
    if(window.location.href.indexOf("#") === -1 && path==""){return;}
    this.router.pause();
    this.router.navigate(path);
    this.router.resume(); // or .pause(false)
  }
}
