import {html, LitElement} from "lit-element";

export class CAPPCheckbox extends LitElement{

    static get properties() {
        return {
            label: {type:String},
            name: {type:String}
        };
    }

    constructor() {
        super();
        this._checked=false;
    }

    createRenderRoot() {
        return this;
    }

    get value() {
        return this._checked;
    }

    set value(val){
        this._checked=val;
        this.requestUpdate();
    }


    render() {
        return html`
            <label>
                <span class="chk-display ${this._checked?'checked':''}"><img alt="Check Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}"></span>
                <input type="checkbox" @change=${this._handleInputChange}> 
                <span>${this.label}</span>
            </label>
        `;
    }

    _handleInputChange(e){
        e.stopPropagation();
        e.preventDefault();

        this._checked=e.target.checked;

        this.requestUpdate();
        this._dispatchChangeEvent();
    }

    _dispatchChangeEvent(){
        let options = {
            detail: this._checked
        };
        const event = new CustomEvent('change', options);
        this.dispatchEvent(event);
    }


}
customElements.define('capp-checkbox',CAPPCheckbox);