export const Helpers={
    getCookie:function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },
    getLanguageFromCookie:function(){
        let lang=Helpers.getCookie('_icl_current_language');
        if(lang==""){lang="en";}
        return lang;
    },
    getTranslatedLanguageFromCookie:function(){
        let lang=Helpers.getCookie('_translated_language');
        if(lang==""){lang="en";}
        return lang;
    },
    deleteCookie:function(cname){
        document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    setCookieWithSeconds(cname, cvalue, expireSec) {
        var d = new Date();
        d.setTime(d.getTime() + expireSec*1000);
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";path=/;" + expires;
    },
    formatNumber:function (num) {
        return num.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    formatDate:function(dateSource, system) {
        var result = [];
        var year = null;
        var month = null;
        var day = null;

        if (typeof dateSource === 'string') {
            var date = dateSource.split(' ')[0].split('-');
            if(date.length <= 2){
                date = dateSource.split(' ')[0].split('/');
                day = date[0].toString();
                month = date[1].toString();
                year = date[2].toString();
            }else{
                year = date[0].toString();
                month = date[1].toString();
                day = date[2].toString();
            }
        } else {
            dateSource = new Date(dateSource);

            year = dateSource.getFullYear().toString();
            month = (dateSource.getMonth() + 1).toString();
            day = dateSource.getDate().toString();
        }

        if (year.length < 2) {
            year = '0' + year;
        }

        if (month.length < 2) {
            month = '0' + month;
        }

        if (day.length < 2) {
            day = '0' + day;
        }


        if (system) {
            result.push(year, month, day);
            result = result.join('-');
        } else {
            result.push(day, month, year);
            result = result.join('/');
        }

        return result;
    },

    formatTime:function(timeSource){
        return timeSource.split(' ')[1];
    },

    formatTimeFromDate:function(date){
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
    },

    /*  
         Remove the localStorage with the expire time
         Param key: Supports the exact key or prefix key with wildcard e.g. "depositData_en" or "depositData_*" 
    */
    removeStorage:function(key, storageType = 'local', expiresCheck = false) {
        const storage = storageType === 'session' ? window.sessionStorage : window.localStorage;

        try {
            if (key.endsWith('*')) {
                const prefix = key.slice(0, -1);

                if (prefix) {
                    const keysToRemove = [];
                    for (let i = 0; i < storage.length; i++) {
                        const storageKey = storage.key(i);
                        if (storageKey.startsWith(prefix)) {
                            keysToRemove.push(storageKey);
                        }
                    }

                    keysToRemove.forEach(storageKey => {
                        storage.removeItem(storageKey);
                        if (expiresCheck) {
                            storage.removeItem(`${storageKey}_expiresIn`);
                        }
                        console.log(`${storageKey} removed from ${storageType}Storage`);
                    });
                }
            } else {
                storage.removeItem(key);
                if (expiresCheck) {
                    storage.removeItem(`${key}_expiresIn`);
                }
            }
        } catch (e) {
            console.log(`removeStorage: Error removing key [${key}] from ${storageType}Storage: ${JSON.stringify(e)}`);
            return false;
        }
        return true;
    },

    /*  
         get the localStorage with the expire time
    */
    getStorage:function(key, storageType, expiresCheck, callbackOnStorageExpired) {
        let storage = window.localStorage;

        if(storageType === 'session'){
            storage = window.sessionStorage;
        }
        else{
            storageType = 'local';
        }

        // Get the expiration time for the storage item
        let expiresIn = 0;

        if(expiresCheck === true){
            // Get the expiration time for the storage item
            expiresIn = storage.getItem(key + '_expiresIn');

            if (expiresIn === undefined || expiresIn === null) {
                expiresIn = 0;
            } else {
                expiresIn = parseInt(expiresIn);  // Ensure expiresIn is an integer
            }
        }

        if (expiresCheck === true && expiresIn < Date.now()) {// Expired

            if(callbackOnStorageExpired !== undefined && callbackOnStorageExpired !== null){
                // If a callback function is provided, execute it
                callbackOnStorageExpired();
                return storage.getItem(key);
            }
            else{ // default action: remove the storage when its expired
                this.removeStorage(key, storageType, true);
                return null;
            }
        } else {
            try {
                return storage.getItem(key);
            } catch(e) {
                console.log('getStorage: Error reading key ['+ key + '] from ' + storageType + 'Storage: ' + JSON.stringify(e));
                return null;
            }
        }
    },

    /*  setStorage: writes a key into localStorage setting a expire time
        params:
            key <string>        : localStorage key
            value <string>      : localStorage value
            storageType <string>: session or local storage type
            expires <number>    : number of seconds from now to expire the key
        returns:
            <boolean> : telling if operation succeeded
    */
    setStorage:function(key, value, storageType, expires) {
        let storage = window.localStorage;

        if(storageType === 'session'){
            storage = window.sessionStorage;
        }
        else{
            storageType = 'local';
        }

        if(expires!==undefined && expires!==null){
            expires = Math.abs(expires); //make sure it's positive
            const now = Date.now();  //millisecs since epoch time, lets deal only with integer
            const schedule = now + expires*1000;
            storage.setItem(key + '_expiresIn', schedule);
        }

        try {
            storage.setItem(key, value);
        } catch(e) {
            console.log('setStorage: Error setting key ['+ key + '] in ' + storageType + 'Storage: ' + JSON.stringify(e));
            return false;
        }
        return true;
    },

    getDateFromUTCDateString(dateString){
        let dateElements=dateString.replace(/-/g, " ").replace(/:/g, " ").split(" ");
        for(let i=0; i<dateElements.length; i++){
            dateElements[i]=parseInt(dateElements[i]);
        }
        return new Date(Date.UTC(dateElements[0],dateElements[1]-1,dateElements[2],dateElements[3],dateElements[4]));
    },

    createLocalDateFromISOString(dateString){
        let date=new Date();
        let dateParts=dateString.split('-');
        date.setFullYear(dateParts[0]);
        date.setMonth(dateParts[1]-1);
        date.setDate(dateParts[2]);
        return date;
    },

    formatLoyaltyPoints:function(amount) {
        if (typeof(amount) === 'undefined') {
            amount = 0;
        }
        amount=parseInt(amount);
        return amount.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },

    findItemInArray(array,field,value){
        for(let i=0; i<array.length; i++){
            if(array[i][field]==value){
                return array[i];
            }
        }
        return null;
    },

    removeItemFromArray(array,item){
        for(let i=0; i<array.length; i++){
            if(array[i]==item){
                array.splice(i,1);
                return;
            }
        }
    },

	removeUrlParam(key, url = window.location.href) {
		var rtn = url.split("?")[0],
		param,
		params_arr = [],
		queryString = (url.indexOf("?") !== -1) ? url.split("?")[1] : "";
		if (queryString !== "") {
			params_arr = queryString.split("&");
			for (var i = params_arr.length - 1; i >= 0; i -= 1) {
				param = params_arr[i].split("=")[0];
				if (param === key) {
					params_arr.splice(i, 1);
				}
			}
			if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
		}
		return rtn;
	},

    getUrlParameter(sParam, url = window.location.href) {
        var sPageURL = decodeURIComponent(url.substring(url.indexOf("?") + 1)),
            sURLVariables = sPageURL.split('&');

        for(var i = 0; i < sURLVariables.length; i++) {
            var key = sURLVariables[i].substring(0, sURLVariables[i].indexOf('='));

            if(key === sParam) {
                //if equals means there is no value entered
                if(sURLVariables[i].indexOf('=') + 1 == sURLVariables[i].length) {
                    return '';
                }
                else {
                    return sURLVariables[i].substring(sURLVariables[i].indexOf('=') + 1);
                }
            }
        }

        return false;//param not found
    },

    //To return root domain from the given domain. E.g. return example.com from www.example.com
    //NOTE: Not applicable to the domains ended with the country code, e.g. example.com.uk
    getRootDomain(hostname = window.location.hostname) {
        const hostnameParts = hostname.split('.');
        return hostnameParts.slice(-2).join('.');
    },

    getCfDynamicImgPath() {
        return 'https://'+this.getRootDomain()+'/cdn-cgi/image/';
    },

    getDeviceType(){
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    },

    isLastDay(dt) {
        return new Date(dt.getTime() + 86400000).getDate() === 1;
    },

    b64_to_utf8( str ) {
        return decodeURIComponent(escape(window.atob( str )));
    },

    ConvertNumericMonthToShortMonthName(monthValue){

        var date = new Date();
        var monthName;
        date.setMonth(monthValue-1);
        if(monthValue == 2){
            monthName='Feb';
        }else{
            monthName = date.toLocaleString('en-US', {
                month: 'short',
              });
        }
        return monthName;
    },

    replaceLinkLanguage(url,lang){
        var modifiedUrl;
        var langCookie=this.getTranslatedLanguageFromCookie();
        if(langCookie!=lang){
             modifiedUrl = url.split('/'+langCookie+'/').join('/'+lang+'/');
        }else{
             modifiedUrl =url;
        }
        
    
         return modifiedUrl;
    },

    //To check if the searchKeyword string contains any of the item in the searchList array
    //E.g. searchList = ['Orange', 'Apple']; searchKeyword = 'Orange @en';
    //Result: TRUE
    containsAnyStringInSearchList(searchList, searchKeyword) {
        return searchList.some(item => searchKeyword.includes(item));
    },
    getFlatpickrLocale(lang){
       
        let locale={"el": "gr","nz":"en"};
     
        if(locale[lang] !==undefined){
            return locale[lang];
        }else{
            return lang;
        }
       

    },
    isRTLLang(lang) {
        let RTLLang=['ar'];


        return RTLLang.includes(lang);
    }
}


