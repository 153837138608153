import {CAPP_API} from "./CAPPApi";
import {EventEmitter} from "events";
import {Helpers} from "../util/Helpers";
const TRANSLATED_LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
class AppStrings extends EventEmitter{
    constructor() {
        super();
        console.log("APP strings started");
        this.wpmlStrings=[];
        this.isWpmlReady = false;
    }

    _init(){
        let wpmlVersion=window.wpmlVersion;
        console.log('current WPML version',wpmlVersion);

        let storedWpmlVersion=window.localStorage.getItem('wpmlVersion_'+TRANSLATED_LANGUAGE);
        console.log('stored WPML version',storedWpmlVersion);

        let wpmlStringsData=window.localStorage.getItem('wpmlStrings_'+TRANSLATED_LANGUAGE);

        let wpmlNeedsUpdate=false;
        if(typeof wpmlVersion=="undefined" || wpmlVersion!=storedWpmlVersion || wpmlStringsData==null){
            wpmlNeedsUpdate=true;
        }

        console.log('load wpml',wpmlNeedsUpdate);

        if(wpmlNeedsUpdate){
            CAPP_API.loadWPMLStrings().then(data=>{
                let stringsData=JSON.parse(atob(data.data));
                console.log("WPML LOADED",data,stringsData);
                window.localStorage.setItem('wpmlStrings_'+TRANSLATED_LANGUAGE, JSON.stringify(stringsData));
                window.localStorage.setItem('wpmlVersion_'+TRANSLATED_LANGUAGE, wpmlVersion);
                this.wpmlStrings=stringsData;
                this.isWpmlReady = true;
                this.emit("WPML_Ready");
            }).catch (e=>{
                console.log("Error loading WPML strings");
                this.isWpmlReady = true;
                this.emit("WPML_Ready");
            });
        }else{
            this.wpmlStrings=JSON.parse(wpmlStringsData);
            this.isWpmlReady = true;
            this.emit("WPML_Ready");
        }
    }

    getWPMLString(id, defaultString){
        if(this.wpmlStrings[id]){
            return this.wpmlStrings[id];
        }else{
            return defaultString ? defaultString : `WPML-ID--${id}`;
        }
    }
}

export const APP_STRINGS=new AppStrings();