import {html} from "lit-element";
import {FormElementBase} from "./FormElementBase";
import flatpickr from "flatpickr";
import { de } from "flatpickr/dist/l10n/de.js"
import { gr } from "flatpickr/dist/l10n/gr.js"
import { no } from "flatpickr/dist/l10n/no.js"
import { ar } from "flatpickr/dist/l10n/ar.js"
import { pl } from "flatpickr/dist/l10n/pl.js"
import {Helpers} from "../../util/Helpers";
import {APP_STRINGS} from "../../core/AppStrings";
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
export class CAPPDatepicker extends FormElementBase{

    static MODE={
        DESKTOP:'desktop',
        MOBILE:'mobile'
    }

    static get properties() {
        return {
            value: {type:String},
            max: {type:String},
            disabled:{type:Boolean},
            monthType:{type:String},
            defaultdate:{type:String}
        };
    }

    constructor() {
        super();
        this._valid=null;
        this._date=null;
        this._dateDisplayData=null;
        this._defaultSet=false;

    }

    get value(){
        return this._inputEl && this._inputEl.value.length>0 ? this._inputEl.value : null;
    }

    set value(val){
        this.date=val&&val.length>0?new Date(val):null;
        this.requestUpdate();
    }

    connectedCallback() {
        console.log('MAX',this.max);
        console.log('this.value',this.value);

        this._mode=Helpers.getDeviceType();
        console.log('datepicker mode',this._mode);
        super.connectedCallback();
    }

    firstUpdated(changedProperties) {
        console.log('first updated');
        this._inputEl=this.renderRoot.querySelector('input');
        if(this._mode==CAPPDatepicker.MODE.DESKTOP){
            let options={locale: Helpers.getFlatpickrLocale(LANGUAGE)};
            if(this.max){
                options.maxDate=this.max;
            }
       
            let fPickr=flatpickr(this._inputEl,options);
           
            if(this.defaultdate && this._defaultSet==false){
                this._defaultSet=true;
                console.log('default date',this.defaultdate);
                fPickr.setDate(this.defaultdate,true);
            }

            console.log(fPickr);
        }else{
            this.value=this.defaultdate;
        }
    }

    _renderInput() {
        return html`
            <div class="input-wrap ${this.disabled?'disabled':''}">
                ${this._date?this._renderDate():this._renderPlaceholderDate()}
                ${this._getValidityIcon()}
                <input name="${this.name}" type="date" max=${this.max} ?disabled=${this.disabled} @change=${this._handleDateChange}>
            </div>
        `
    }

    _renderPlaceholderDate(){
        return html`
            <span>${APP_STRINGS.getWPMLString('DatepickerComponent-placeholder-dd')}</span>
            <span>${APP_STRINGS.getWPMLString('DatepickerComponent-placeholder-mm')}</span>
            <span>${APP_STRINGS.getWPMLString('DatepickerComponent-placeholder-yyyy')}</span>
        `;
    }
    _renderDate(){
        return html`
            <span>${this._dateDisplayData.day}</span>
            <span>${this._renderMonth()}</span>
            <span>${this._dateDisplayData.year}</span>
        `;
    }

    _renderMonth(){
        let month;
        if(this.monthType=='MMM'){
           month =Helpers.ConvertNumericMonthToShortMonthName(this._dateDisplayData.month);
        }else{

            month =this._dateDisplayData.month;
        }
        return month;
    }
   
    set valid(value){
        this._valid=value;
        this.requestUpdate();
    }

    set date(value){
        this._date=value;
        this._prepareDateDisplayData();
        let dateString=this._date.toISOString().split('T')[0];
        let input=this.renderRoot.querySelector('input');
        if(input){
            input.value=dateString;
        }
        this.requestUpdate();
    }

    _getValidityIcon(){
        if(this._valid==null) return;
        if(this._valid){
            return html`<img class="icon success" alt="Valid Input Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}" >`
        }else{
            return html`<img class="icon warning" alt="Invalid Input Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}" >`
        }
    }

    _handleDateChange(e){
        let val=e.target.value;
        if(val==''){
            this._resetDateInput();
            return;
        }

        this._date=new Date(val);
        this._prepareDateDisplayData();
        this.requestUpdate();
        this._dispatchChangeEvent();

    }

    _resetDateInput(){
        this._date=null;
        this._dateDisplayData=null;
        this.requestUpdate();
    }

    _prepareDateDisplayData(){
        if(!this._date){return;}
        this._dateDisplayData={
            day:this._date.getDate().toString(),
            month:(this._date.getMonth()+1).toString(),
            year:this._date.getFullYear()
        }
        if(this._dateDisplayData.day.length==1){
            this._dateDisplayData.day=`0${this._dateDisplayData.day}`;
        }
        if(this._dateDisplayData.month.length==1){
            this._dateDisplayData.month=`0${this._dateDisplayData.month}`;
        }
    }

    _dispatchChangeEvent(){
        let options = {
            detail: this.value
        };
        const event = new CustomEvent('change', options);
        this.dispatchEvent(event);
    }
}
customElements.define('capp-datepicker',CAPPDatepicker);