
export const FormHelpers={
    getFormDataAsObject:form=>{
        let inputs=form.querySelectorAll('capp-input, capp-checkbox, capp-datepicker, capp-select, capp-multiselect, capp-radio, capp-text');
        let data={};

        inputs.forEach(input=>{
            data[input.name]=input.value;
        })
        return data;
    },
    getFormElements:form=>{
        let inputs=form.querySelectorAll('capp-input, capp-checkbox, capp-datepicker, capp-select, capp-multiselect, capp-radio, capp-text');
        return inputs;
    }
}