import {LitElement,html} from "lit-element";
import {POPUP_MANAGER} from "../../controllers/PopupManager";

export const POPUP_MOBILE_ALIGN={
    TOP:'mobile-top',
    BOTTOM:'mobile-bottom'
}
export const POPUP_DESKTOP_ALIGN={
    CENTER:'desktop-center',
    LEFT:'desktop-left',
    RIGHT:'desktop-right'
}


export class PopupBase extends LitElement{


    constructor(mobileAlign,desktopAlign) {
        super();
        this._mobileAlign=mobileAlign;
        this._desktopAlign=desktopAlign;
        POPUP_MANAGER.addPopup(this);
    }

    createRenderRoot() {
        return this;
    }

    render(){
        return html`
            <div class="capp-popup-overlay">
                <div class="popup-layout-control ${this._mobileAlign} ${this._desktopAlign}">
                    <div class="popup">
                        ${this._renderContent()}
                    </div>
                </div>
            </div>
        `;
    }


    _renderContent(){
        throw new Error(`_renderContent needs to be implemented in the subclass`)
    }

    _show(managerCloseCallback){
        document.body.appendChild(this);
        document.body.classList.add('popup-no-scroll');
        this._managerCloseCallback=managerCloseCallback;
    }

    _close(){
        document.body.classList.remove('popup-no-scroll');
        document.body.removeChild(this);
        this._managerCloseCallback();
    }

}