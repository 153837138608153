import {CAPP_API} from "./CAPPApi";
import {USER} from "./User";
import {EventEmitter} from "events";
import {Helpers} from "../util/Helpers";
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
class PageSettings extends EventEmitter{
    constructor() {
        super();
        console.log("Page Settings started");
    }

    _init(){
        let pageSettingsNeedsUpdate = true; // Keep this for future enhancement(if any)
        console.log('load pageSettings', pageSettingsNeedsUpdate);

        if(pageSettingsNeedsUpdate){
            let userPreferredLanguage = LANGUAGE;
           
            let sessionKey = '';
            if(USER.isLoggedIn){
                sessionKey =USER.data.sessionKey
               
            }
            
            CAPP_API.getPageSettings(userPreferredLanguage,sessionKey).then(data=>{ //getPageSettings() - misc_functions.php
                if(!data.error){
                    console.log("PAGE SETTINGS DATA LOADED",data);
                    if((typeof data.csrfToken) !== 'undefined'){
                        window.csrfToken = data.csrfToken;
                        window.isCountryRestricted = data.isCountryRestricted; // true | false
                        window.localRegulationMode = data.localRegulationMode; // "none" | "session" | "infinite"
                        window.localRegulationPopup = data.localRegulationPopup; // true | false
                        window.isCookieWarningShow = data.isCookieWarningShow; // true | false
                        window.isBot = data.isBot; // true | false
                        window.maintenanceNotice = data.maintenanceNotice; // maintenance notice string or empty string
                        window.noCachePageSettings = data.noCachePageSettings;

                        if(data.chatId){
                            window.chatId = data.chatId;
                        }

                        if(data.lcGroupId){
                            window.__lc.group = data.lcGroupId;
                        }

                        if((typeof data.clientExternalID) !== 'undefined'){ //NOTE: This attr can be used as the humanUid in ProtoCX integration
                            window.clientExternalID = data.clientExternalID;
                        }

                        if (data.OTPSwitchSettings) {
                            window.OTPSwitchSettings = data.OTPSwitchSettings;
                        }

                        if (data.ftRewardsSID) {
                            window.sid = data.ftRewardsSID;
                        }
                       if(data.isLanguageUpdated){
                          USER.updateUserData();
                       }
                        /*if(data.playerDetails){
                            //window.localStorage.setItem('userData', JSON.stringify(data.playerDetails));
                            Helpers.setStorage('userData', JSON.stringify(data.playerDetails), 'local', window.userDataTtl);
                            USER._processLoggedInUserData();
                            USER._invalidateExistingDepositData();
                            //USER.data=data.playerDetails;
                            //USER.sessionKey=USER.data.sessionKey;
                            //USER.emit("onLoginStateChange",{loggedIn:true});
                            console.log(`User details is auto refreshed`, data.playerDetails);
                        }*/
                        //window.localStorage.setItem('pageSettings', data); // Won't be used until certain cases are clarified(e.g. to handle situation of player switching the VPN country & etc.)
                        console.log("PAGE SETTINGS VARIABLES READY",data);
                    }
                }
                else{
                    console.log("PAGE SETTINGS LOADED WITH ERROR",data);
                }
                this.emit("PageSettings_Ready");
            }).catch (e=>{
                console.log("Error loading page settings");
                this.emit("PageSettings_Ready");
            });
        }else{
            this.emit("PageSettings_Ready");
        }
    }
}

export const PAGE_SETTINGS=new PageSettings();