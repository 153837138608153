import {USER} from "../core/User";
import {Helpers} from "../util/Helpers";
import {SubViewManager} from "./SubViewManager";
import {GAHelpers} from "../util/GAHelpers";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

const CHAT_WIDGET_INIT_EVENTS = ['mousedown', 'mousemove', 'scroll', 'keydown', 'touchstart', 'touchmove']; //Events that triggers the chat widget to start
let CHAT_WIDGET_HIDE_ON_INIT = false;
let CHAT_CONSTRUCTOR_INITIALIZED = false;

export const InitLivechatController=(hideWidgetOnInit)=>{
    if(window.isCountryRestricted === false && window.isBot !== true){ //#19637 - Feedback For CAPP - Optimization - Don't launch the LC for the Restricted Countries.
        //#21825 - EC- Optimization - SEO - R&D - Only Start Loading Resources on User Activities - LC and Subsequent Hero Banners
        CHAT_WIDGET_HIDE_ON_INIT = hideWidgetOnInit;
        CHAT_WIDGET_INIT_EVENTS.forEach(key => {
            console.log(key, 'addEventListener');
            document.addEventListener(key, initChatConstructor);
        });
    }
}

export const ShowChatButton=()=>{
    if(typeof LiveChatWidget !== 'undefined'){
        //if(LC_API.chat_window_maximized()==false){
            try {
                LiveChatWidget.call("minimize");
            }catch (e){}
        //}
    }
}

export const HideChatButton=()=>{
    if(typeof LiveChatWidget !== 'undefined'){
        try {
            LiveChatWidget.call("hide");
        }catch (e){}
    }
}

export const ShowChatWindow=()=>{
    GAHelpers.gaEvent('LiveChat','livechatClick');
    if(typeof LiveChatWidget !== 'undefined'){
        try {
            LiveChatWidget.call("maximize");
        }catch (e){}
    }
}

export const SetLivechatDepositErrorCustomVariable=paymentType=>{
    if(typeof LiveChatWidget !== 'undefined'){
        let variables=Object.assign({},getCustomVaribles(),{"DepositFailed": paymentType});
        LiveChatWidget.call("set_session_variables", variables);
    }
}

export const AddLivechatCustomVariable=variable=>{
    if(typeof LiveChatWidget !== 'undefined'){
        try {
            let variables=Object.assign({},getCustomVaribles(),variable);
            LiveChatWidget.call("set_session_variables", variables);
        }catch (e){}
    }
}

function setLoggedOutMode(){
    if(typeof LiveChatWidget !== 'undefined'){
        if(window.hideChatWindowOnLogout){
            LiveChatWidget.call("hide");
        }
        console.log('set_session_variables', {});
        LiveChatWidget.call("set_session_variables",{});
    }
}

function initChatConstructor(){
    console.log(CHAT_CONSTRUCTOR_INITIALIZED, 'CHAT_CONSTRUCTOR_INITIALIZED');
    //#21825 - EC- Optimization - SEO - R&D - To remove the events
    CHAT_WIDGET_INIT_EVENTS.forEach(key => {
        console.log(key, 'removeEventListener');
        document.removeEventListener(key, initChatConstructor);
    });

    if(!CHAT_CONSTRUCTOR_INITIALIZED){
        CHAT_CONSTRUCTOR_INITIALIZED = true;
        //setTimeout(()=>{
            console.log(window.__lc);
            if((typeof window.__lc) !== 'undefined'){
                initChat();
                USER.on("onLoginStateChange",()=>{
                    console.log(USER);
                    initChat();
                })
            }
        //},5000); //#20321 - SEO - EC Livechat - Defer loading of LC button to 7 seconds after page
    }
}

function initChat(){
    //Asynchronous Initialization
    if((typeof LiveChatWidget) === 'object'){
        LiveChatWidget.call("destroy");
        console.log('LiveChatWidget destroyed');
    }
    console.log(window.__lc);
    window.__lc.asyncInit = true;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice)) 

    //setTimeout(()=>{
        setConf();
        LiveChatWidget.init();
        console.log('LiveChatWidget initialized');
    //},5000); //#20321 - SEO - EC Livechat - Defer loading of LC button to 5 seconds after page
}

function setConf(){
    console.log('setConf', CHAT_WIDGET_HIDE_ON_INIT);

    if(USER.isLoggedIn){
        setLoggedInMode();
        if(CHAT_WIDGET_HIDE_ON_INIT){
            LiveChatWidget.call("hide");
        }else{
            LiveChatWidget.call("minimize");
        }
    }else{
        setLoggedOutMode();
    }
}

function setLoggedInMode(){
    if(typeof LiveChatWidget !== 'undefined'){
        console.log('set_session_variables', getCustomVaribles());
        LiveChatWidget.call("set_session_variables", getCustomVaribles());
        /*__lc.visitor={
            name: `${USER.data.FirstName} ${USER.data.LastName}`,
            mail: USER.data.EmailAddress
        }*/
        LiveChatWidget.call("set_customer_name", `${USER.data.FirstName} ${USER.data.LastName}`);
        LiveChatWidget.call("set_customer_email", USER.data.EmailAddress);
    }
}

function getCustomVaribles() {
    return {
        "First Name": USER.data.FirstName,
        "Email": USER.data.EmailAddress,
        "Platform": Helpers.getDeviceType(),
        "Current Real Balance": CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(USER.data.CurrentRealBalance)),
        "CurrentBonusBalance": CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrency(USER.data.CurrentBonusBalance)),
        "LoyaltyLevelName": USER.data.PlatformVIPLevelName,
        "AccountActivated": USER.data.AccountActivated,
        "KycChecked": USER.data.IsKYCChecked,
        "PlayerProfileUrl": USER.data.playerProfileUrl
    };
}