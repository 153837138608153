import {SubViewManager} from "../../../../core/capp-V3.5-js-lib/src/js/controllers/SubViewManager";
import {CAPP_API} from "../../../../core/capp-V3.5-js-lib/src/js/core/CAPPApi";
import {PAGE_SETTINGS} from "../../../../core/capp-V3.5-js-lib/src/js/core/PageSettings";
import {APP_STRINGS} from "../../../../core/capp-V3.5-js-lib/src/js/core/AppStrings";
import {USER} from "../../../../core/capp-V3.5-js-lib/src/js/core/User";
import {CAPP_ROUTER} from "../core/CAPPRouter";

import {CAPPPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/CAPPPopup";
import {LoginSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/LoginSubview";
import {RegistrationSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/RegistrationSubview";
import {ResetPasswordSubView} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/ResetPasswordSubView";
import {InitLivechatController} from "../../../../core/capp-V3.5-js-lib/src/js/controllers/LivechatController";
import {RestrictedCountryPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/RestrictedCountryPopup";
import {SUB_VIEW_POSITION} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/SubViewBase";

export class CAPPStandaloneSignupPage{
    constructor(defaultView) {
        window.defaultView=defaultView;
        this._waitForAPPStrings();
    }

    init(){
        console.log('STANDALONE SIGN UP PAGE - initialized');

    }

    _waitForAPPStrings(){
        APP_STRINGS.on("WPML_Ready",()=>{
            console.log("WPML loaded!!!");
            console.log('CAPP STRINGS',APP_STRINGS);
            console.log('USER',USER);

            this._setLocalRegulationsMode();
            this._setAffiliateAcquisitionTracking();
            this._listenForServerErrors();
            this._initializeRouter();
            this.init();
            this._pageLoaded();
        })
        APP_STRINGS._init();
    }

    _setLocalRegulationsMode(){
        if(window.localRegulationMode && window.localRegulationMode!="none"){
            RegistrationSubview.REGISTRATION_MODE=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS;
        }
    }

    _setAffiliateAcquisitionTracking(){
        //Check and remove the expired affData from localStorage(if exist)
        if(window.localStorage.getItem('affData')){
            console.log(window.localStorage.getItem('affData'), 'affData');

            try{
                const affData = JSON.parse(window.localStorage.getItem('affData'));
                const currentDate = new Date().toJSON();
                console.log(currentDate, 'currentDate');

                if((typeof affData) === 'object' && affData !== null){

                    if(affData.expiry <= currentDate){
                        //Remove the expired affData
                        console.log(affData.expiry, 'Removing the expired affData');
                        window.localStorage.removeItem('affData');
                    }
                }
            }catch (e){
                console.log(e);
            }
        }

        let url = new URL(window.location.href);

        if(url.searchParams.get("affToken") || window.localStorage.getItem('affData') === null){
            console.log(url.searchParams, '_setAffiliateAcquisitionTracking');
            const expiryDays = 30; // expires in days
            let d = new Date();
            d.setTime(d.getTime() + expiryDays*24*60*60*1000);

            const affData = {
                expiry: d.toJSON(),
                affToken: url.searchParams.get("affToken"),
                affID: url.searchParams.get("affID"),
                affDetails: url.searchParams.get("affDetails"),
                referer: url.searchParams.get("referer"),
                refererURL: document.referrer
            };
            window.localStorage.setItem('affData', JSON.stringify(affData));
        }
    }

    _pageLoaded(){
        document.body.classList.remove("loading");

        PAGE_SETTINGS.on("PageSettings_Ready",()=>{
            console.log("Page Settings loaded!!!");

            this._checkIfCountryRestricted();
            InitLivechatController(true);
        });
        PAGE_SETTINGS._init();
    }

    _listenForServerErrors(){
        CAPP_API.on("ServerError",(error)=>{
            console.log("server error event",error);
            new CAPPPopup(APP_STRINGS.getWPMLString('serverError-popup-title'),error,[{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}]);
        });

        CAPP_API.on("SessionExpired",(error)=>{
            console.log("SESSION EXPIRED",error);
            new CAPPPopup(APP_STRINGS.getWPMLString('serverError-popup-title'),error.errorMessage,[{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}],()=>{
                SubViewManager.closeCurrentSubview();
                USER.logout(true);
            });
        });
    }

    _initializeRouter(){
        CAPP_ROUTER.initialize();
        CAPP_ROUTER.on('route-changed',this._handleRouteChange);
        this._handleRouteChange(CAPP_ROUTER.routeData);

    }

    _handleRouteChange(routeData){
        console.log('***handle route change', routeData);
        if(USER.isLoggedIn){
            window.location.href=window.location.origin;
        }
        this.componentHolder=document.querySelector('#active-component-holder');
        function getDefaultView(){
            let defView;
            switch (window.defaultView){
                case "signup":
                    defView=new RegistrationSubview();
                    break;
                case "login":
                    defView=new LoginSubview();
                    break;
            }
            return defView;
        }


        let view;
        if(routeData==null){
            view=getDefaultView();
            view._targetHolder=this.componentHolder;
            view.position=SUB_VIEW_POSITION.LEFT;
            view.open();
            return;
        }

        switch (routeData.action){
            case "login":
                if(USER.isLoggedIn){
                    window.location.href=window.location.origin;
                }else{
                    view=new LoginSubview();
                    view.redirectUrl=window.location.origin;
                    view._targetHolder=this.componentHolder;
                    view.position=SUB_VIEW_POSITION.LEFT;
                    view.open();
                }
                break;
            case "signup":
                view=new RegistrationSubview();
                view._targetHolder=this.componentHolder;
                view.position=SUB_VIEW_POSITION.LEFT;
                view.open();
                break;
            case "resetPassword":
                view=new ResetPasswordSubView();
                view._targetHolder=this.componentHolder;
                view.position=SUB_VIEW_POSITION.LEFT;
                view.open();
                break;
            default:
                CAPP_ROUTER.setRoute("");
                view=getDefaultView();
                view._targetHolder=this.componentHolder;
                view.position=SUB_VIEW_POSITION.LEFT;
                view.open();

        }
    }

    _checkIfCountryRestricted(){
        if(window.localRegulationMode && window.localRegulationMode!="none"){
            this._handleLocalRegulations(window.localRegulationMode);
        }else if(window.isCountryRestricted){
            new RestrictedCountryPopup(csrfToken);
        }
    }

    _handleLocalRegulations(mode){
        let storage;
        switch (mode){
            case "session":
                storage=window.sessionStorage;
                break;
            case "infinite":
                storage=window.localStorage;
                break;
            default:
                storage=window.localStorage;
        }

        if(window.localRegulationPopup){

            if(storage.getItem('localRegulationsDisplayed')=="true"){
                return;
            }

            new CAPPPopup(
                APP_STRINGS.getWPMLString('LocalRegulationsPopup-title'),
                APP_STRINGS.getWPMLString('LocalRegulationsPopup-text'),
                [{label:APP_STRINGS.getWPMLString('LocalRegulationsPopup-acceptButton'),type:'btn-primary'}],
                ()=>{
                    storage.setItem('localRegulationsDisplayed',"true");
                }
            )
        }
    }
}